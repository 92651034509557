<template>
	<div class="interestsCenter">
		<div class="title">
			ONE DEX   {{ $t('lang.治理中心') }}
		</div>
		<div class="goVote">
			{{ $t('lang.去投票') }}
		</div>
		<van-swipe class="swiper_list" ref="swipeRef" :show-indicators="indicatorDots"   @change="change">
			<van-swipe-item class="item">
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(1)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.董事竞选') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.竞选成为管理委员会') }}	<br /> {{ $t('lang.董事') }}
					</div>
				</div>
				<div class="swiper_list_item">
					<img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(1)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.发起提案') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.发起社区发展提案') }}
					</div>
				</div>
			</van-swipe-item>
			<van-swipe-item class="item">
				<div class="swiper_list_item">
          <img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(0)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.投票决议') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.参与提案投票表决') }}
					</div>
				</div>
				<div class="swiper_list_item">
          <img src="@/assets/img/next_icon.png" class="next_icon" mode="" @click="swiperTo(0)" />
					<div class="swiper_list_item_title">
						{{ $t('lang.获得激励') }}
					</div>
					<div class="swiper_list_item_text">
						{{ $t('lang.获得税收分红激励') }}
					</div>
				</div>
			</van-swipe-item>
		</van-swipe>
		<div class="interestsCenter_box">
			<div class="box_title">
				{{ $t('lang.节点激励') }}
			</div>
			<div class="box_line">
				<img src="@/assets/img/jili1.png" class="image" mode="" />
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.已领取') }}
					</div>
					<div class="box_line_item_num">
						{{nodeInfo.claimed}}
					</div>
				</div>
				<div class="box_line_item">
					<div class="box_line_item_title">
						{{ $t('lang.待领取') }}
					</div>
					<div class="box_line_item_num">
							{{nodeInfo.usable}}
					</div>
				</div>
				<div class="box_line_button" @click="nodewalletclaim" v-if="nodeInfo.usable>0">
					{{ $t('lang.领取') }}
				</div>
				<div class="box_line_button" style="opacity: 0.6;" v-else>
					{{ $t('lang.领取') }}
				</div>
			</div>
		
			<div style="display: flex; width: 47%; align-items: center; justify-content: space-between;">
				<div :class="checkListActive == 0 ? 'box_title':'box_title box_title_noactive'">
					{{ $t('lang.节点账单') }}
				</div>
			
			</div>
            <div>
				<div class="box_list" @scroll="handleScroll1">
					<block v-if="nodelist.list.length!=0">
						<div class="box_list_li">
							<div class="box_list_li_item">
								{{ $t('lang.时间') }}
							</div>
							<div class="box_list_li_item">
								{{ $t('lang.数量') }}
							</div>
							<div class="box_list_li_item">
								{{ $t('lang.来源') }}
							</div>
						</div>
				
						<div class="box_list_li" v-for="(item,index) in nodelist.list">
							<div class="box_list_li_item">
								{{item.created_at}}
							</div>
							<div class="box_list_li_item" style="color: rgba(102, 180, 174, 1);">
								{{item.usable_change}}
							</div>
							<div class="box_list_li_item" style="color: rgba(34, 34, 34, 1);">
								{{item.type_txt}}
							</div>
						</div>
					</block>
					<div class="nolist" v-else>
						<image src="@/static/img/no_list.png" mode=""></image>
						<div class="">
							{{ $t('lang.暂无数据') }}！
						</div>
					</div>
				</div>
			</div>
			

		</div>
		<van-popup
		  v-model="popupshow"
		  mode="center"
		  @close="closepopupshow"
		  border-radius="20"
		>
		  <div class="popup">
		    <image
		      src="@/static/img/reg_ture.png"
		      mode=""
		      style="width: 84px; height: 84px"
		    ></image>
		    <text
		      style="
		        font-size: 16px;
		        color: #000;
		        margin-top: 32px;
		        margin-bottom: 49px;">{{ $t('lang.领取成功') }}</text>
		    <div class="popup-button" @click="closepopupshow">{{ $t('lang.确定') }}  </div>
		  </div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showExitDialog: false,
				indicatorDots: false,
				autoplay: false,
				interval: 3000,
				duration: 500,
				checkListActive:0,
				popupshow:false,
				current:0,
				daolist:{
					pageInfo: {
						page: 1,
						status: 'more'
					},
					list: []
			},
				nodelist:{
						pageInfo: {
							page: 1,
							status: 'more'
						},
						list: []
				},
				daoInfo:{
					usable:0,
					claimed:0,
				},
				nodeInfo:{
					usable:0,
					claimed:0,
				}
				
			};
		},
		onLoad() {
          
		},
		components: {
		
		},
		computed: {

		},
		methods: {
			change() {

			},
			swiperTo(index){
				this.$refs.swipeRef.swipeTo(index)
			},
			closepopupshow(){
				this.popupshow = false
			},
			handleScroll1 (e) {
			    const container = event.target;
			       this.nodewalletrecordlist();
				
			},
			handleScroll2 (e) {
			    const container = event.target;
			       this.daowalletrecordlist();
				
			},
			checkListActiveFun(index){
				this.checkListActive = index
			},
			init(){
				this.daolist={
						pageInfo: {
							page: 1,
							status: 'more'
						},
						list: []
				}
				this.nodelist={
						pageInfo: {
							page: 1,
							status: 'more'
						},
						list: []
				}
				this.daowallet()
				this.daowalletrecordlist()
				this.nodewallet()
				this.nodewalletrecordlist()
			},
			daowallet(){
				mintApi.daowallet().then(res=>{
					 this.daoInfo = res.data
				})
			},
			daowalletrecordlist() {
				let _p = this.daolist.pageInfo
				if (_p.status != 'more') return;
				_p.status = 'loading'
				mintApi.daowalletrecordlist({
					page: _p.page,
					page_size: 20,
				}).then(res => {
					if (res.code == 200) {
						res = res.data
						if (!_p.pages) _p.pages = res.page_size
						this.daolist.list = this.daolist.list.concat(res.list)
					if(res.list.length<20){
						_p.status = "nomore"
					}else{
						++_p.page
						_p.status = "more"
					}
					}
				})
			},
			nodewalletrecordlist() {
				let _p = this.nodelist.pageInfo
				if (_p.status != 'more') return;
				_p.status = 'loading'
				mintApi.nodewalletrecordlist({
					page: _p.page,
					page_size: 20,
				}).then(res => {
					if (res.code == 200) {
						res = res.data
						if (!_p.pages) _p.pages = res.page_size
						this.nodelist.list = this.nodelist.list.concat(res.list)
					if(res.list.length<20){
						_p.status = "nomore"
					}else{
						++_p.page
						_p.status = "more"
					}
					}
				})
			},
			daowalletclaim(){
				mintApi.daowalletclaim({}).then(res=>{
					 this.popupshow = true;
					 if(res.code == 200){
						 this.popupshow = true;
						 this.init()
					 }
				})
			},
			nodewallet(){
				mintApi.nodewallet().then(res=>{
					 this.nodeInfo = res.data
				})
			},
			nodewalletclaim(){
				mintApi.nodewalletclaim({}).then(res=>{
					 if(res.code == 200){
						  this.popupshow = true;
						 this.init()
					 }
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.interestsCenter {
		width: 100%;
		min-height: 100vh;
		padding-bottom: 76px;
		background: url('~@/assets/img/interestsCenter_bg.png') no-repeat;
		background-size: contain;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			margin: 54px 0 31px 0;
			font-size: 28px;
			font-weight: 700;
                text-align: center;
			color: rgba(34, 34, 34, 1);
		}

		.goVote {
			width: 191px;
			height: 48px;
			border-radius: 72px;
			background: rgba(102, 180, 174, 1);
			box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 700;
			color: rgba(255, 255, 255, 1);
		}
	}

	.swiper_list {
		margin-bottom: 25px;
		margin-top: 264px;
		width: 100%;
		height: 100px;
		border-bottom: 3px solid rgba(102, 180, 174, 1);

		.item {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-around;

			.swiper_list_item:nth-child(2n) {
				background: linear-gradient(0deg, rgba(102, 180, 174, 0.18) 0%, rgba(255, 255, 255, 0) 100%);

				.swiper_list_item_title {
					color: rgba(46, 46, 46, 1);
				}
			}

			.swiper_list_item {
				position: relative;
				padding-top: 15px;
				width: 158px !important;
				height: 100px;
				opacity: 1;
				background: linear-gradient(0deg, rgba(102, 180, 174, 0.71) 0%, rgba(255, 255, 255, 0) 100%);
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
                 .next_icon{
					 position: absolute;
					 width: 20px;
					 height: 20px;
					 right: -10px;
					 top: 50%;
					 transform: translateY(-50%);
				 }
				.swiper_list_item_title {
					margin-bottom: 15px;
					color: rgba(69, 135, 143, 1);
					font-size: 22px;
					font-weight: 700;
				}

				.swiper_list_item_text {
					line-height: 18px;
					text-align: center;
					color: rgba(102, 102, 102, 1);
					font-size: 13px;
					font-weight: 500;
				}
			}

		}

	}

	.interestsCenter_box {
		border-radius: 30px 30px 0px 0px;
		background: rgba(255, 255, 255, 1);
		width: 100%;
		padding: 20px 18px;
		padding-bottom: 40px;

		.nolist {
			width: 100%;
			height: 300px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 400;
			color: rgba(153, 153, 153, 1);

			image {
				width: 171.07px;
				height: 121.69px;
				margin-bottom: 19px;
			}
		}

		.box_title {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 700;
			color: rgba(46, 46, 46, 1);
		}
		.box_title_noactive{
			font-size: 16px;
			font-weight: 500;
			color: rgba(153, 153, 153, 1);
		}

		.box_line {
			margin-bottom: 28px;
			padding: 19px 14px;
			width: 100%;
			height: 182rpx;
			opacity: 1;
			border-radius: 10px;
			background: linear-gradient(225deg, rgba(250, 255, 255, 1) 0%, rgba(214, 242, 255, 1) 100%);
			display: flex;
			align-items: center;
			justify-content: space-between;

			.image {
				width: 54px;
				height: 54px;
			}

			.box_line_item {
				height: inherit;
				display: flex;
				flex-direction: column;

				justify-content: center;

				.box_line_item_title {
					font-size: 12px;
					font-weight: 500;
					opacity: 0.3;
					color: rgba(0, 0, 0, 1);
					margin-bottom: 12px;
				}

				.box_line_item_num {
					font-size: 20px;
					font-weight: 500;
					color: rgba(31, 149, 144, 1);
				}
			}

			.box_line_button {
				align-self: self-end;
				width: 48px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				background: rgba(102, 180, 174, 1);
				color: rgba(255, 255, 255, 1);
				font-size: 12px;
			}
		}
	}

	.box_list {
		padding: 16px;
		width: 100%;
		height: 304px;
		opacity: 1;
		border-radius: 14px;
		overflow-y: scroll;
		border: 1px solid rgba(242, 242, 242, 1);

		.box_list_li {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.box_list_li_item:nth-child(1) {
				text-align: left;
			}

			.box_list_li_item:nth-child(3) {
				text-align: right;
			}

			.box_list_li_item {
				flex: 1;
				text-align: center;
				margin-bottom: 20px;
				color: rgba(153, 153, 153, 1);
				font-size: 14px;
				font-weight: 700;
			}
		}
	}
	.popup {
	  width: 304px;
	  height: 311px;
	  opacity: 1;
	  border-radius: 20px;
	  background: rgba(255, 255, 255, 1);
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	
	  .popup-button {
	    font-size: 16px;
	    font-weight: 700;
	    letter-spacing: 0px;
	    line-height: 21px;
	    color: #fff;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    width: 246px;
	    height: 45px;
	    opacity: 1;
	    border-radius: 8px;
	    background: rgba(102, 180, 174, 1);
	  }
	}
</style>