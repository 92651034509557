import { render, staticRenderFns } from "./chobi.vue?vue&type=template&id=7db797b2&scoped=true&"
import script from "./chobi.vue?vue&type=script&lang=js&"
export * from "./chobi.vue?vue&type=script&lang=js&"
import style0 from "./chobi.vue?vue&type=style&index=0&id=7db797b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db797b2",
  null
  
)

export default component.exports