<template>
    <div class="navbar">
        <div class="nav_box" v-for="(item, index) in NarData" :key="index" @click="bNav(item,index)">
            <div class="nav_box_icon">
                <img :src="NarDataIndex == index? item.active_icon :item.icon" alt="">
            </div>
            <span :style="NarDataIndex == index? 'color: rgba(102, 180, 174, 1);':''">{{ $t(item.name)  }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            NarDataIndex:0,
            NarData: [
                {
                    name: 'lang.权益中心',
                    router: 'interestsCenter',
                    icon: require('@/assets/img/icon/interestsCenter.png'),
                    active_icon: require('@/assets/img/icon/interestsCenter-active.png'),
                },

                {
                    name: 'lang.提案公投',
                    router: 'recruitment',
                    icon: require('@/assets/img/icon/recruitment.png'),
                    active_icon: require('@/assets/img/icon/recruitment-active.png'),
                },
                {
                    name: 'lang.席位招募',
                    router: '',
                    icon: require('@/assets/img/icon/referendum.png'),
                    active_icon: require('@/assets/img/icon/referendum-active.png'),
                }
            ]
        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
        bNav(Data,index) {
   

      if (Data.router != '') {
        this.NarDataIndex = index;
        this.$router.push({ name: Data.router });
      } else {
        this.$toast(this.$t('lang.暂未开放'));
      }
    },
    },
}

</script>
<style scoped lang='less'>
.navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 74px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background: rgba(255, 255, 255, 1);
box-shadow: -4px 0px 8px  rgba(0, 0, 0, 0.12);
    .nav_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        .nav_box_icon {
            width: 24px;
            height: 24px;
            margin-bottom: 5px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>