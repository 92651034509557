<template>
	<div class="nftcenter">
		<div class="recruit_title">
			<div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
			<div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
			<div></div>
		</div>
		<div class="title">
			{{ $t('lang.专属NFT') }}
		</div>
		<div style="padding: 20px; padding-top: 0;">
			<div class="holdNft">
				<!-- <swiper class="swiper" circular :indicator-dots="indicatorDots" :autoplay="autoplay"
					:interval="interval" :duration="duration" @change="change">
					<swiper-item v-for="(item, index) of topnftlist" :key="index">
						<div class="nftshow">
							<div class="nftshow_abs">持有张数 {{item.hold_num}} 张</div>
							<image :src="fmtImg(item.logo)" mode=""></image>
						</div>
					</swiper-item>
				</swiper> -->
				<van-swipe class="swiper" :autoplay="3000" indicator-color="white" @change="onChange">
					<van-swipe-item v-for="(item, index) in topnftlist" :key="index">
						<div class="nftshow">
							<div class="nftshow_abs"> {{ $t('lang.持有张数') }} {{ item.hold_num }} {{ $t('lang.张') }}</div>
					        <img :src="fmtImg(item.logo)" alt="">
							
						</div>
					</van-swipe-item>
				</van-swipe>
				<div class="nft_title">
					<div class="">
						{{ $t('lang.所属系列') }}
					</div>
					<div>{{nftlistItem.cate}} 《{{nftlistItem.name}}》 </div>
				</div>
				<div class="nft_button">
					<div class="button_left">{{ $t('lang.挂卖') }}</div>
					<div class="button_right">{{ $t('lang.转赠') }}</div>
				</div>
			</div>
		
		</div>
		<div class="routerlist">
			<div class="routerlist_li" v-for="(item, index) in routerlist" :key="index" @click="menuClick(item.url)">
				<div class="routerlist_li_img">
					<img :src="item.image" mode="" />
				</div>
				<div class="routerlist_li_title">{{ $t(item.title)  }}</div>
			</div>
		</div>
		<div class="title_box">
			{{ $t('lang.NFT系列') }}
		</div>
		<div class="nftlist">
			<div class="nftlist_li" v-for="(item, index) in nftlist" :key="index">
				<template v-if="item.isOpen">
					<div class="nftshow">
						<div class="nftshow_abs">{{ $t('lang.持有张数') }} {{ item.hold_num }} {{ $t('lang.张') }} </div>
						<img :src="fmtImg(item.logo)"  alt=""/>
					</div>
					<!-- <image :src="fmtImg(item.logo)" mode=""></image> -->
					<div>{{ $t('lang.所属系列') }}:</div>
					<div style="color: rgba(15, 23, 42, 1);"> {{item.cate}}</div>
					<div style="color: rgba(15, 23, 42, 1);">《{{item.name}}》</div>
					<!-- <div>推出时间:</div>
					<div class="">
						{{ customDateRange(item.begin_date, item.end_date) }}
					</div> -->
				</template>
				<template v-else>
					<div class="nftshow">
						<img src="@/assets/img/no_nft.png" mode="" />
					</div>

					<div>{{ $t('lang.所属系列') }}:</div>
					<div style="color: rgba(15, 23, 42, 1);">----</div>
					<div style="color: rgba(15, 23, 42, 1);">----</div>
					<!-- <div>推出时间:</div>
					<div>---</div> -->
				</template>
			</div>
		</div>
		<div style="padding: 20px; padding-top: 0; margin-bottom: 30px;">
			<div class="joinBox">
				<div class="joinBox_title">
					{{ $t('lang.加入我们的社区') }}
				</div>
				<div class="joinBox_text">
					{{ $t('lang.核心价值观影响着社区的文化，并决定前进的道路。如何听取更多的声音，及时有效的向所有社区成员传达讯息，显得极为重要，在未来进行治理模型时，既要着眼于未来，亦要注重当前的需求，兼顾个人和集体，不断提高，我们乐于与您共同参与到未来的建设！') }}	
				</div>
				<div class="joinBox_button" @click="JoinFun" v-if="!isJoin">
					{{ $t('lang.加入社区') }}
				</div>
				<div class="joinBox_button" style="opacity: 0.6;" v-else>
					{{ $t('lang.已加入社区') }}
				</div>
			</div>
		</div>
	
		<!-- <u-popup v-model="popupshow" mode="center" @close="closepopupshow" border-radius="20">
			<div class="popup">
				<image src="@/assets/img/reg_ture.png" mode="" style="width: 84px; height: 84px"></image>
				<text style="
            font-size: 16px;
            color: #000;
            margin-top: 32px;
            margin-bottom: 49px;
          ">{{ popupshowText }}</text>
				<div class="popup-button" @click="closepopupshow"> 确定 </div>
			</div>
		</u-popup> -->
	</div>
</template>

<script>


import { nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
import basicUrl from "@/config/configUrl/configUrl";
export default {
	data() {
		return {
			showExitDialog: false,
			indicatorDots: false,
			autoplay: true,
			interval: 3000,
			duration: 500,
			popupshow: false,
			popupshowText: '加入成功',
			email: '',
			isJoin: false,
			is_subscribed: false,
			nftlistItem: {},
			nftlist: [],
			topnftlist: [],
			routerlist: [{
				image: require('@/assets/img/shichang_icon.png'),
				title: 'lang.市场',
				url: ''
			},
			{
				image: require('@/assets/img/zhuanzen_icon.png'),
				title: 'lang.转赠',
				url: ''
			},
			{
				image: require('@/assets/img/kuangchi_icon.png'),
				title: 'lang.矿池',
				url: ''
			},
			{
				image: require('@/assets/img/jilu_icon.png'),
				title: 'lang.记录',
				url: ''
			}
			],
			holdlistIng: {
				loading: false,
				finished: false,
				list: [],
				info: {
					page: 1,
					pageSize: 10,
					status: -1,
					activationStatus: 1
				}

			},
		};
	},
	computed: {
    ...mapState(['account', 'lang']),
  },
	watch: {
		account() {

			this.init()
		},
		lang() {
			this.init()
		}
	},
	created() {
		this.init()
	},
	components: {

	},
	methods: {
		init() {
		  this.isJoin =	localStorage.getItem(this.account + '-isJoin')
			 this.nftsendconfigs()
		},

	
	
		toprouter() {
			this.$router.go(-1);
		},
		closepopupshow() {
			this.popupshow = false
		},
		menuClick(url) {
			if (url != "") {

				this.$Router.pushPage({
					path: url,
				});


			} else {
				this.$toast("暂未开放");
			}
		},
		JoinFun() {
			this.popupshow = true
			this.popupshowText = '加入成功'
			this.isJoin = true
			console.log(localStorage.getItem(this.account + '-isJoin'));
			localStorage.setItem(this.account + '-isJoin' , true);
		},
		nftsendconfigs() {
			nft.nftsendconfigs(this.account).then(res => {
				if(res.code == 200){
					let list = res.data
					this.topnftlist = [...res.data]
					this.nftlistItem = this.topnftlist[0]
					let listlength = list.length
					let sum = 4
					let PushItem = {
						isOpen: false
					}
					for (let i = 0; i < list.length; i++) {
						list[i].isOpen = true
					}
					for (let i = 0; i < sum - listlength; i++) {

						list.push(PushItem)
					}


					this.nftlist = list
				}
				

				})
		},
		
		fmtImg(val) {
			if (!val) return ''
			return basicUrl.http_url + val
		},
		customDateRange(startDateStr, endDateStr) {
			// 转换开始时间和结束时间为时间戳
			function dateToTimestamp(dateString) {
				if (!dateString) return null;
				return Math.floor(new Date(dateString).getTime() / 1000);
			}

			var startTimestamp = dateToTimestamp(startDateStr);
			var endTimestamp = dateToTimestamp(endDateStr);

			// 将时间戳转换为自定义日期格式
			function timestampToCustomDate(startTimestamp, endTimestamp) {
				if (!startTimestamp || !endTimestamp) {
					return "--年--月--日";
				}

				var startDate = new Date(startTimestamp * 1000);
				var endDate = new Date(endTimestamp * 1000);

				var startYear = startDate.getFullYear();
				var startMonth = startDate.getMonth() + 1; // 月份从0开始，所以要加1
				var startDay = startDate.getDate();
				var endYear = endDate.getFullYear();
				var endMonth = endDate.getMonth() + 1;
				var endDay = endDate.getDate();

				var customDate;

				if (startYear === endYear) {
					if (startMonth === endMonth) {
						customDate = startYear + "年" + startMonth + "月" + startDay + "日至" + endDay + "日";
					} else {
						customDate = startYear + "年" + startMonth + "月" + startDay + "日至" + endMonth + "月" + endDay +
							"日";
					}
				} else {
					customDate = startYear + "年" + startMonth + "月" + startDay + "日至" + endYear + "年" + endMonth +
						"月" + endDay + "日";
				}

				return customDate;
			}

			// 调用转换函数并返回结果
			return timestampToCustomDate(startTimestamp, endTimestamp);
		},
		onChange(index) {

this.nftlistItem = this.topnftlist[index]
},
		nftholdlistFUn() {
			let data = {
				page: 1,
				page_size: 10
			}
			mintApi.nftholdlist(data).then(res => {
                 
			})
		},
		nftsubscription() {
			if (this.email == '') return uni.showToast({
				title: '请输入邮箱',
				icon: 'none'
			})
			mintApi.nftsubscription({
				email: this.email
			}).then(res => {
				if (res.code == 200) {
					this.popupshow = true
					this.popupshowText = '订阅成功'
					this.init()
				} else {
					uni.showToast({
						title: res.msg,
						icon: 'none'
					})
				}

			})
		}

	}
}
</script>

<style lang="less" scoped>
.nftcenter {
	position: relative;
	padding-top: 50px;
	padding-bottom: 51px;
	background: #fff;

	.title {
		margin-left: 20px;
		font-size: 20px;
		font-weight: 700;
		color: #000;
		margin-top: 20px;
		margin-bottom: 14px;
	}

	.title_box {
		width: 113px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		background: rgba(62, 125, 136, 0.1);
		font-size: 12px;
		font-weight: 700;
		color: rgba(62, 125, 136, 1);
		margin-top: 24px;

		margin-left: 24px;
	}
}

.joinBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 21px 24px;
	border-radius: 20px;
	background: rgba(255, 255, 255, 1);

	border: 1px solid rgba(240, 240, 240, 1);

	.joinBox_title {
		font-size: 20px;
		font-weight: 400;
		color: rgba(102, 180, 174, 1);
		margin-bottom: 14px;
	}

	.joinBox_text {
		font-size: 14px;
		font-weight: 500;
		color: rgba(102, 102, 102, 1);
		margin-bottom: 23px;
	}

	.joinBox_button {
		width: 223px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		background: rgba(102, 180, 174, 1);
		font-size: 18px;
		font-weight: 500;
		color: rgba(255, 255, 255, 1);
	}
}

.nftlist {
	padding: 20px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	.nftlist_li {
		padding: 10px;
		width: 48%;
		height: 630rpx;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 18px;
		border: 1px solid rgba(240, 240, 240, 1);
		border-radius: 16px;
		box-shadow: 0px -1px 24px rgba(0, 0, 0, 0.04);

		.nftshow {
			width: 100%;
			height: 386rpx;
			border-radius: 16px;
			margin-bottom: 10px;
			position: relative;
			

			img {
				border-radius: 10px;
				width: 100%;
				height: 193px;
			}

			.nftshow_abs {
				z-index: 999;
				width: 204rpx;
				height: 25px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 10px;
				background: rgba(255, 255, 255, 0.3);
				border: 1px solid rgba(255, 255, 255, 0.53);
				font-size: 12px;
				font-weight: 500;
				color: #fff;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				.img{
					 
				}
			}
		}

		// image {
		// 	width: 100%;
		// 	height: 386rpx;
		// 	border-radius: 16px;
		// 	margin-bottom: 10px;
		// }

		font-size: 12px;
		color: #000;
		line-height: 18px;
		color: rgba(148, 163, 184, 1);
	}
}

.routerlist {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border-top: 8px solid rgba(247, 252, 250, 1);
	border-bottom: 8px solid rgba(247, 252, 250, 1);
	padding: 24px 0;

	.routerlist_li {
		width: 25%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.routerlist_li_img {
			width: 40px;
			height: 40px;
			opacity: 1;
			border-radius: 50%;
			background: rgba(247, 249, 251, 1);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.routerlist_li_title {
			margin-top: 10px;
			font-size: 14px;
			font-weight: 500;
			color: rgba(15, 23, 42, 1);
		}
	}
}

.holdNft {
	padding: 16px;
	width: 100%;
	border-radius: 20px;
	background: rgba(255, 255, 255, 1);

	border: 1px solid rgba(240, 240, 240, 1);

	.nft_button {
		margin-top: 29px;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;

		.button_left {
			width: 58%;
			color: rgba(15, 23, 42, 1);
			background: rgba(247, 249, 251, 1);
		}

		.button_right {
			color: rgba(255, 255, 255, 1);
			width: 38%;
			background: rgba(15, 23, 42, 1);
		}

		&>div {
			border-radius: 10px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 700;
		}
	}

	.nft_title {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 500;
		color: rgba(15, 23, 42, 1);
		margin-top: 19px;
	}

	.swiper {
		width: 100%;
		height: 904rpx;
	}

	.nftshow {
		width: 100%;
		height: 904rpx;
		position: relative;
		

		.nftshow_abs {
			z-index: 999;
			width: 151px;
			height: 35px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 10px;
			background: rgba(255, 255, 255, 0.3);
			border: 1px solid rgba(255, 255, 255, 0.53);
			font-size: 14px;
			font-weight: 500;
			color: #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			border-radius: 10px;
			width: 100%;
			height: 411px;
		}
	}
}

.popup {
	width: 304px;
	height: 311px;
	opacity: 1;
	border-radius: 20px;
	background: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.popup-button {
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0px;
		line-height: 21px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 246px;
		height: 45px;
		opacity: 1;
		border-radius: 8px;
		background: rgba(102, 180, 174, 1);
	}
}

.recruit_title {
	color: var(rgba(255, 255, 255, 1));
	padding: 20px 20px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>