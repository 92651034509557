<template>
    <div class="c2cOrder">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="functional_region">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index,item.status)"
                    :class="index == regionIndex ? 'special' : ''">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="OrderList.list.length>0">
            <van-list v-model="OrderList.loading" :finished="OrderList.finished" :finished-text="`${$t('lang.d145')}`"
            @load="onLoadList">

            <div class="li" v-for="(item, index) in OrderList.list" :key="index"">
            <div class=" li_item">
                <div class="li_item_tips" v-if="item.type == '0'">
                    {{ $t('lang.买单') }} 
                </div>
                <div class="li_item_tips" v-else-if="item.type == '1'" style="background: rgba(102, 180, 174, 1);">
                    {{ $t('lang.卖单') }}  
                </div>
                <div class="li_item_status" v-if="regionIndex == '0'" @click="c2cmarketcancel(item.id)">
                    {{ $t('lang.取消订单') }} 
                </div>
                <div class="li_item_status" v-else-if="regionIndex == '1'">
                    {{ $t('lang.已完成') }}   
                </div>
                <div class="li_item_status" v-if="regionIndex == '2'" style="border: 1px solid rgba(255, 141, 26, 1); color: rgba(255, 141, 26, 1);">
                    {{ $t('lang.已取消') }}    
                </div>
            </div>
            <div class="li_item">
                <div class="li_item_left"> {{ $t('lang.订单号') }} : </div>
                <div class="li_item_right">{{ item.order_no }}</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.星石单价') }}:</div>
                <div class="li_item_right">{{ item.price }}</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.星石总量') }}:</div>
                <div class="li_item_right">x{{ item.amount }}</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.星石总价') }}:</div>
                <div class="li_item_right">{{ parseFloat(item.price * item.amount).toFixed(2) }} ONE</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.已完成数量') }}: </div>
                <div class="li_item_right">x{{ item.amount - item.remain_amount }}</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.可撤销数量') }}: </div>
                <div class="li_item_right">x{{ item.remain_amount }}</div>
            </div>
            <div class="li_item">
                <div class="li_item_left">{{ $t('lang.发布时间') }}: </div>
                <div class="li_item_right">{{ item.created_time }}</div>
            </div>
        </div>
        </van-list>
         
    </div>
    <div class="list" v-else>
        <div class="no_list">
            <img src="@/assets/imgsnowball/no_list_icon.png" class="no_list_icon" alt="">
            <div class="no_data">{{ $t('lang.暂无订单') }}</div>
        </div>
        
    </div>
    </div>
</template>

<script>
import { c2c, nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    data() {
        return {
            functionOption: [
                { 
                name: 'lang.发布中',
                status:0 

            }, 
            { name: 'lang.已完成',status:2 },
             { name: 'lang.已取消',status:1 }
            ],
            regionIndex: '0',
            OrderList: {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    pageSize: 10,
                    status:0,
                    type: -1
                }

            },
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    components: {

    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    mounted() {

    },
    created() {
        this.init()
    },
    methods: {
        toprouter() {
            this.$router.go(-1);

        },
        switchovers(index,status) {
            this.OrderList.info.status = status
            this.OrderList.loading = false
                this.OrderList.finished = false
                this.OrderList.list = []
                this.OrderList.info.page = 1
                this.OrderList.info.pageSize = 10
            this.regionIndex = index;
             this.c2ctradelist()
        },
        init(){
            if (this.account) {
                this.OrderList.loading = false
                this.OrderList.finished = false
                this.OrderList.list = []
                this.OrderList.info.page = 1
                this.OrderList.info.pageSize = 10
                this.OrderList.info.status = this.OrderList.info.status
                this.OrderList.info.type = this.OrderList.info.type
            }
            this.c2ctradelist()
        },
        onLoadList(){
            this.c2ctradelist()
        },
        async c2cmarketcancel(id) {
            let sign = null
                try {
                    let web3 = new Web3(window.ethereum);
                    sign = await web3.eth.personal.sign(web3.utils.fromUtf8('C2C Cancel'), this.account);
                } catch (error) {
                    return;
                }
                let data ={
                    id:id,
                    signature:sign
                }
            c2c.c2cmarketcancel(data, this.account).then(res => {
                        if(res.code == 200){
                            this.init()
                        }
                       this.$toast(res.msg)                        
            })
        },
        c2ctradelist(){
            c2c.c2ctradelist(this.OrderList.info, this.account).then(res => {
                if (res.data.list != null || res.data.list.length > 0) {
                    if (this.OrderList.info.page == 1) {
                        this.OrderList.list = res.data.list
                    } else {
                        this.OrderList.list = this.OrderList.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.OrderList.info.pageSize) {
                        this.OrderList.loading = true
                        this.OrderList.finished = true
                    } else {
                        this.OrderList.info.page++
                    }

                }

            })
        }
    },
}

</script>
<style scoped lang='less'>
.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.functional_region {

    padding: 20px 20px 0;
    padding-top: 0;

    .region {
        display: flex;
        width: 100%;
        line-height: 22px;
        font-size: 16px;
        border-radius: 30px;
        padding: 3px;
        border: 1px solid rgba(102, 180, 174, 1);
        font-family: PingFangSC-Semibold, PingFang SC;
        color: rgba(160, 209, 204, 1);
        font-weight: 600;
        // background:  rgba(69, 131, 255, 1);
        border-radius: 34px;
        // margin: 20px 0;
        margin-bottom: 20px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            text-align: center;
            // width: 192rpx;
            padding: 16px 0;
        }

        .special {
            // width: 50%;
            color: #fff;
            background: rgba(102, 180, 174, 1);
            // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
            border-radius: 34px;
        }
    }
}

.c2cOrder {
    padding-top: 52px;
    padding-bottom: 44px;

}

.list {
    border-radius: 30px 30px 0px 0px;
    background: rgba(255, 255, 255, 1);
    padding: 19px 18px;
    min-height: calc(100vh - 100px);
    .no_list{
        height: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
           color: rgba(153, 153, 153, 1);
           font-size: 14px;
        .no_list_icon{
        width: 171.07px;
        height: 121.69px;
    }
    }
  

    .li {
        margin-bottom: 15px;
        border-radius: 10px;
        padding: 17px 14px;
        border: 1px solid rgba(240, 240, 240, 1);

        .li_item {
            margin-bottom: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .li_item_status {
                padding: 10px 16px;
                font-size: 14px;
                font-weight: 700;
                border-radius: 6px;
                border: 1px solid rgba(102, 180, 174, 1);
                color: rgba(102, 180, 174, 1);
            }

            .li_item_tips {
                padding: 10px 28px;
                font-size: 14px;
                font-weight: 700;
                color: rgba(255, 255, 255, 1);
                border-radius: 6px;
                background: rgba(42, 130, 228, 1);
            }
        }
    }
}
</style>