//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
  
]



