export const lang = {
    swap1: '플래시',
    swap2: '즉시 점멸 시작',
    swap3: 'from',
    swap4: 'balance: ',
    swap5: '통화 선택',
    swap6: 'max',
    swap7: '가격',
    swap8: '미끄러짐 공차',
    swap9: '지갑 연결',
    swap10: '수량을 입력하세요',
    swap11: '잔액 불충분',
    swap12: '거래가 충분히 유동적이지 않습니다',
    swap13: '토큰 선택',
    swap14: '인정 받은',
    swap15: '패키지',
    swap16: '짐 등에서 꺼내다',
    swap17: '플래시',
    swap18: '거래 영수증',
    swap19: '확인을 기다리는 중',
    swap20: '움직이고 있다',
    swap21: '교환',
    swap22: '지갑에서 이 거래를 확인하세요',
    swap23: '제출된 트랜잭션',
    swap24: 'BSC에서 보기',
    swap25: '폐쇄',
    swap26: '트랜잭션 거부됨',
    swap27: '교환 확인',
    swap28: '출력이 예상되며, 최소 {inputOutMin} {symbol}을(를) 얻거나 트랜잭션이 철회됩니다.',
    swap29: '가치로 입력하면 {inputInMax} {symbol}까지 매도하지 않으면 거래가 철회됩니다.',
    swap30: '최소취득금액',
    swap31: '최대 판매',
    swap32: 'to',
    swap33: '토큰 관리',
    swap34: '가져오기 토큰',
    swap35: '수입',
    swap36: '토큰 관리',
    swap37: '맞춤 토큰',
    swap38: '모두 지우기',
    swap39: '이름 검색 또는 주소 붙여넣기',
    swap40: '설정',
    swap41: '기본 트랜잭션 속도(GWEI)',
    swap42: '기준',
    swap43: '빠른',
    swap44: '즉각적인',
    swap46: '유효한 미끄러짐 비율을 입력하세요.',
    swap47: '거래가 실패할 수 있습니다',
    swap48: '귀하의 거래가 앞당겨질 수 있습니다',
    swap49: '거래마감시간(분)',
    swap50: '추정',
    swap51: '멀티홉 비활성화',
    swap52: '최근 거래',
    swap53: '최근 거래 없음',
    swap54: '모두 지우기',
    swap55: '링크 지갑',
    swap56: '加入董事会',
    swap57: '捐赠',
    swap58: '权益赠送',
    swap59: '董事会成员列表',
    swap60: '초대하다',
    swap61: '복사',
    swap62: '我的DAPP邀请人:',
    swap63: '邀请记录',
    swap64: '出错啦，请稍后再试',
    swap65: '功能暂未开放',
    swap66: '领取',
    swap67: '取出成功',
    swap68: '捐赠中，待确认',
    swap69: '已捐赠',
    swap70: '非白名单用户',
    swap71: '捐赠无效',
    swap72: '当前余额不足',
    swap73: '授权中，请稍等',
    swap74: '授权失败',
    swap75: '捐赠中，请稍等',
    swap76: '捐赠失败',
    swap77: '교환',
    swap78: '유동성',
    swap79: '회원',
    swap80: '초대하다',
    swap81: '지분 풀',
    swap82: '跨链桥',
    swap83: '池子',
    swap84: '农场',
    swap85: '공식 웹 사이트',
    swap86: '시장',
    swap87: '교환',
    swap88: '거래',
    swap89: '벌다',
    swap90: '확인하다',
    swap91: '가격 영향',
    swap92: '최소취득금액',
    swap93: '유동성 공급자 수수료',
    swap94: '유동성을 증가',
    swap95: 'LP 토큰을 받기 위한 유동성 증가',
    swap96: '유동성 풀의 주식',
    swap97: '환율 및 유동성 풀 지분',
    swap98: '당신의 유동성',
    swap99: '유동성을 제거하여 토큰 복구',
    swap100: '유동성 없음',
    swap101: '가입한 흐름 풀이 보이지 않습니까?',
    swap102: '다른 LP 토큰 보기',
    swap103: '수영장에 들어갔다',
    swap104: '제거하다',
    swap105: '공급',
    swap106: '가져오기 풀',
    swap107: '기존 유동성 풀 가져오기',
    swap108: '유동성을 찾을 코인을 선택하세요.',
    swap109: '이 유동성 풀에 유동성을 제공하지 않았습니다.',
    swap110: '지갑의 LP 토큰',
    swap111: '인계하다',
    swap112: '和',
    swap113: '金额',
    swap114: '你将获得',
    swap115: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
    swap116: '已焚毁',
    swap117: '已入金',
    swap118: '确认供应',
    swap119: '汇率',
    swap120: '您是第一个流动性供应商。',
    swap121: '您添加代币的比列，将决定该流动性池的初始价格。',
    swap122: '如果您对汇率满意，请点击“供应”以进行检查。',
    swap123: '正在移除',
    swap124: '正在供应',
    swap125: '确认供应',
    swap126: '白皮书',
    swap127: '审计',
    swap128: '公告',
    swap129: '提示',
    swap130: 'PHO 1:1 兑换',
    swap131: '更多',
    swap132: '正在加载',
    swap133: '信息',
    swap134: '捐赠名额已满',
    swap135: '我的PHO',
    swap136: '钱包中的PHO',
    swap137: '可收割的PHO',
    swap138: 'PHO价格',
    swap139: 'PHO总量',
    swap140: 'PHO市值',
    swap141: 'PHO信息',
    swap142: '已赚取',
    swap143: '质押',
    swap144: '个人TVL',
    swap145: '社区TVL',
    swap146: '收割',
    swap147: '累计激励 ',
    swap148: '今日激励',
    swap149: '参与市商地址数',
    swap150: '我的邀请链接',
    swap151: '最大算力区',
    swap152: '其他算力区',
    swap153: '我的好友邀请',
    swap154: '취소',
    swap155: '修改邀请人',
    swap156: '邀请人不能为空',
    swap157: '修改成功',
    swap158: '质押LP代币',
    swap159: '赎回LP代币',
    swap160: '赎回',
    swap161: '获取',
    swap162: '当前暂无收益',
    swap163: '라우팅',
    swap164: '감사',
    swap165: '파트너',
    swap166: '发行',
    swap167: '活动',
    swap168: '单币池',
    swap169: 'LP池',
    swap170: '国会',
    swap171: '卡牌',
    swap172: '盲盒',
    swap173: '游戏',
    swap174: '权益池已分配总额',
    swap175: '待领取数额',
    swap176: '我已获取数额',
    swap177: '取出',
    swap178: '超级节点',
    swap179: '权益卡已领取份额',
    swap180: '国库',
    swap181: '国库累计金额',
    swap182: '国库已分配金额',
    swap183: '国库已回购金额',
    swap184: '전문가 모드',
    swap185: 'D6 Swap是基于OKExChain研发的全网首个股权税收制Defi平台，逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念，提供更加广泛的资产增值方案和前所未有的体验感，深受广大用户喜爱。',
    swap186: '一键取出',
    swap187: '价格影响过高',
    swap188: 'FAQ',
    swap189: 'NFT',
    swap190: '赚币',
    swap191: '进行中',
    swap192: '已结束',
  
    swap193: '说明',
    swap194: '股权权益生态卡，全球限量1680张',
    swap195: '点亮股权权益生态卡的要求及收益如下',
    swap196: '一星卡：个人地址拥有价值1000USDT流动性，团队1000USDT流动性，收益共分交易税收0.1%',
    swap197: '二星卡：个人拥有价值2000USDT流动性，团队1万USDT流动性，收益共分交易税收0.1%',
    swap198: '三星卡：个人3000USDT流动性，团队10万USDT流动性，设立工作室，收益共分交易税收0.1%',
    swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室，收益共分交易税收0.1%',
    swap200: '五星卡：个人5000USDT流动性，团队1000万USDT流动性，设立工作室，收益共分交易税收0.1%',
    swap201: '备注：所有权益生态卡收益全部链上自动分配，权益卡享受交易所所有生态分红，权益卡可传承可交易可转让，满足要求自动升级，不满足要求自动降级及自动退出。',
    swap202: '权益卡',
    swap203: '发展路线图：',
    swap204: '2022年7月  2.0版上线',
    swap205: '2022年9月  开通持币多挖',
    swap206: '2022年12月 NFT交易平台上线',
    swap207: '2023年 6月  Goodbaby游戏上线',
    swap208: '2023年 9月  Goodlend上线',
    swap209: 'D6 Swap介绍：',
    swap210: '滑点：',
    swap211: '分配：',
    swap212: '买入',
    swap213: '卖出',
    swap214: '0.5% LP分红',
    swap215: '0.5% 生态权益卡分红',
    swap216: '0.25% 回流底池销毁LP',
    swap217: '0.25% 生态建设',
    swap218: '暂无奖励领取',
    swap219: '个人TVL',
    swap220: '团队TVL',
    swap221: 'LP数量',
    swap222: '钱包中的pho',
    swap223: 'GDT',
    swap224: 'GDT空投累计奖励',
    swap225: '星级用户累计奖励',
    swap226: '免手续费倒计时',
    swap227: '可取出奖励',
    swap228: 'LP挖矿奖励',
    swap229: '质押pho',
    swap230: '赎回pho',
    swap231: '目标共识',
    swap232: '已达共识',
    swap233: '已共识',
    swap234: '共识',
    swap235: '已解锁',
    swap236: '可领取',
    swap237: '社区治理',
    swap238: '联盟共识',
    swap239: '社区联盟',
    swap240: '质押代币',
    swap241: '质押中，请稍等',
    swap242: '交易挖矿',
    swap243: '昨日新增',
    swap244: '社区白名单累计奖励',
    swap245: '股权星卡累计奖励',
    swap246: 'LP挖矿累计奖励',
    swap247: '当前可领取',
    swap248: '当前可领取金额',
    swap249: '交易挖矿',
    swap250: '我的分享奖励累计',
    swap251: '我的交易奖励累计',
    swap252: '请输入您的交易哈希',
    swap253: '校验',
    swap254: '请输入您的哈希',
    swap255: '您的交易地址',
    swap256: '您将获得',
    swap257: '仅买入GDT及创建流动性的交易可获得奖励',
    swap258: '质押GDT-PHO代币',
    swap259: '赎回GDT-PHO代币',
    swap260: '全网手续费累计',
    swap261: '待领取分红',
    swap262: 'GDT联合股东',
    swap263: '社交',
    swap264: '商城',
    swap265: '精选好物',
    swap266: '优选小店',
    swap267: '即将上线',
    swap268: 'VGT联创股东',
    swap269: '质押VGT-PHO代币',
    swap270: '赎回VGT-PHO代币',
    swap271: '推荐链接',
    swap272: '리더를 묶어주세요',
  
    swap273: '상세한',
    swap274: '메타버스',
    swap275: '글로벌',
    swap276: '교환 및 유동성',
    swap277: '거래, 개발, 돈 벌기,',
    swap278: '다중 체인 집계 DEX 생태 플랫폼',
    swap279: '가장 낮은 거래 수수료, 수익성 있는 기능 및 다중 유형 추천 프로그램을 즐기십시오.',
    swap280: '노선지도',
    swap281: '2022년 7월 버전 2.0 출시 예정',
    swap282: '2022년 9월 코인 보유 및 멀티 마이닝 오픈 예정',
    swap283: '2022년 12월 NFT 거래 플랫폼 출시 예정',
    swap284: '2023년 6월 굿베이비 게임 출시 예정',
    swap285: '2023년 9월 Goodlend 출시 예정',
    swap286: '더 알아보기',
    swap287: '당신은 가지고',
    swap288: '존재하다',
    swap289: '암호화된 디지털 화폐 거래',
    swap290: '유동성 및 토큰 스테이킹',
    swap291: '보너스 보상 받기',
    swap292: '발사대',
    swap293: '인증',
    swap294: '고품질 암호화 디지털 자산',
    swap295: '배우다',
    swap296: '삽화',
    swap297: '시장',
    swap298: '검색',
    swap299: '즉각적인 토큰 교환',
    swap300: '성공적으로 복사',
    swap301: '복사가 지원되지 않습니다. 이 브라우저는 자동 복사를 지원하지 않습니다.',
    swap302: 'Lianchuang 누적 보상',
    swap303: '누적 공유 보상',
    swap304: '누적 거래 보상',
    swap305: '자산 팩',
    swap306: '코인을 얻기 위한 거래',
    swap307: '출금 잔액',
    swap308: '请输入交易哈希',
    swap309: '确定',
    swap310:'请输入hash值',
    swap311:'성공적으로 등록되었습니다',
  
    swap312:'币安',
    swap313:'平台',
    swap314:'PHO专区',
    swap315:'GDT专区',
    swap316:'VGT专区',
  swap317:'PHO星级卡',
  swap318:'个人',
  swap319:'团队',
  swap320:'钱包中的',
  swap321:'选择资产包',
  swap322:'请选择',
  swap323:'日收益率',
  swap324:'收益比例',
  swap325:'到期总数',
  swap326:'购买',
  swap327:'您的钱包余额',
  swap328:'我的资产包',
  swap329:'今日总收益',
  swap330:'今日静态收益',
  swap331:'今日动态收益',
  swap332:'累计收益',
  swap333:'到期数量',
  swap334:'选择',
  swap335:'加密货币专区',
  swap336:'购买成功',
  swap337:'DApp 입력',
  swap338:'원',
  swap339:'암호화 서클 친구와 채팅',
  swap340:'사탕 상자',
  swap341:'다양한 고품질 자산 에어드롭',
  swap342:'무료로 받으러 오세요',
  swap343:'군중 혁신 풀',
  swap344:'민트 센터',
  swap345:'커뮤니티 서클',
  swap346:'사탕 상자',
  swap347:'돈을 얻다',
  swap348:'도구',
  swap349:'공유 센터',
  swap350:'제휴 프로그램',
  swap351:"사회적 접촉",
  swap352:"다리",
  swap353:"지도",
  swap354:"Water 마이닝 풀",
swap355:"마이닝 풀 건설에 참여하고 자유롭게 높은 인센티브를 받으세요",
  swap356:"유동성 채굴 풀",
  swap357:"단일 통화 서약 풀",
  swap358:"실시간",
  swap359:"완전한",
  swap360:"스테이크 전용",
  swap361:"정렬 기준",
  swap362:"찾다",
  swap363:" 인기 있는 ",
  swap364:"검색 풀",
  swap365:"여기에는 아무것도 없습니다!",
  swap366:"첫 번째 Water 스왑을 위한 시작 템플릿",
  swap367:"각각의 우량 투자자와 암호화 자산에 대한 최적의 매칭 경로 찾기 ",
  swap368:"ISO 만들기",
  swap369:"미래의 암호화폐 스타가 되십시오",
  swap370:"곧",
  swap371:"추천인을 먼저 묶어주세요",
swap372:"나의 초대자",
  swap373:"초청자 주소:",
  swap374:"",
  swap375:"3분기에는 D6 스왑이 런칭 및 런칭되었고, 제네시스 코인이 런칭되었으며, 암호화된 소셜 플랫폼 CIRCLE이 동시에 도입되어 커뮤니티의 생태계 발전을 깊숙이 연결했습니다.",
  swap376:"Q4 D6에 유동성을 주입하고 “D6 합의 계획”을 시작하고 D6 순환 합의를 만들고 유동성 인센티브 계획을 활성화합니다. 체인 연합 국경 간 쇼핑몰을 시작하고 “소비 부가가치” 생태를 만들고 커뮤니티와 비즈니스의 결합을 위한 새로운 방향을 촉진합니다.",
  swap377:"",
  swap378:"Q1 D6를 기본 유동성 지원으로 추진, 시장 인지도 및 비중 제고, D-Pay 출시, D6 Coin의 실물 거래 순환 촉진을 위한 제휴 지원 패키지 계획",
  swap379:"Q2는 공식적으로 D6 Trade를 출시하여 CEX 및 DEX 이중 거래 플랫폼 시대를 열고 D6 시장의 거래 유동성을 더욱 개선하고 새로운 파트너를 소개합니다.",
  swap380:"",
  swap381:"",
  
  d1:"보증금",
  d2:"코인 출금",
  d3:"옮기다",
  d4:"청구서",
  d5:"지역 사회",
  d6:"플래시",
  d7:"피드백",
  d8:"주조",
  d9:"D6 코인 획득",
  d10:"최대 혜택",
  d11:"입력하다",
  d12:"Water 시장",
  d13:"오늘의 가격",
  d14:"오늘의 수입",
  d15:"확인하다",
  d16:"자산",
  d17:"제로 자산 숨기기",
  d18:"통화",
  d19:"꼭 매달리게 하다",
  d20:"사용 가능",
  d21:"토큰 선택",
  d22:"충전량",
  d23:"충전금액을 입력해주세요",
  d24:"지갑 잔액:",
  d25:"계정 주소",
  d26:"확신하는",
  d27:"충전 기록",
  d28:"인출 금액",
  d29:"출금수량을 입력해주세요",
  d30:"계정 잔액:",
  d31:"도착 주소",
  d32:"출금기록",
  d33:"이체 금액",
  d34:"이체금액을 입력해주세요",
  d35:"상대방의 주소를 입력하세요.",
  d36:"받는 사람 주소를 입력해주세요",
  d37:"이체 기록",
  d38:"자산 교환",
  d39:"지불하다",
  d40:"얻다",
  d41:"수량",
  d42:"모두",
  d43:"다음과 같이 최대로 사용할 수 있습니다.",
  d44:"거래세:",
  d45:"계정으로 인해:",
  d46:"지불 수량:",
  d47:"플래시 레코드",
  d48:"교환 확인",
  d49:"플래시 레코드",
  d50:"적립",
  d51:"보류 소득",
  d52:"연소량",
  d53:"소각량을 입력해주세요",
  d54:"진행 중",
  d55:"~ 위에",
  d56:"순환 진행",
  d57:"기록이 없습니다!",
  d58:"과거 수입",
  d59:"내 등급",
  d60:"현재 레벨",
  d61:"등록 후 D0 사용자가 될 수 있습니다.",
  d62:"친구 초대 프로그램",
  d63:"친구들을 초대하여 코인을 함께 주조하고, Water 합의 혁명을 시작하고, 더 빨리 보상을 받으세요!",
  d64:"링크 복사",
  d65:"我的推荐人",
  d66:"나의 유효한 추천",
  d67:"주소",
  d68:"수준",
  d69:"성취",
  d70:"다음 레벨:",
  d71:"시장 실적",
  d72:"완료되지 않음",
  d73:"마케팅 부서",
  d74:"당신은 이미 최고 수준에 있습니다",
  d75:"발표",
  d76:"바인딩 확인",
  d77:"리더 주소:",
  d78:"구속력을 확인하다",
  d79:"음수 및 0일 수 없음",
  d80:"잔액 불충분",
  d81:"성공적인 입금",
  d82:"입금 실패",
  d83:"수수료:",
  d84:"편입",
  d85:"전출하다",
  d86:"청구 기록",
  d87:"시간",
  d88:"유형",
  d89:"수량",
  d90:"통화",
  d91:"결제 토큰을 먼저 선택하세요.",
  d92:"이 토큰은 양방향 교환을 금지합니다.",
  d93:"동일한 토큰을 선택할 수 없습니다.",
  d94:"리더를 먼저 묶어주세요",
  d95:"남은 가연성 양",
  d96:"추천인을 먼저 묶어주세요",
  d97:"추천인 초대코드를 입력해주세요",
  d98:"최신 발표",
  d99:"메모:",
  d100:"2: 출금 주문은 T+1일에 도착할 것으로 예상됩니다.",
  d101:"1: 1회 인출 시 {min_withdraw} {token_name}부터 시작",
  d102:"3: 인출할 때마다 2 USDT 세금이 부과됩니다.",
  d103:"팀 주소",
  d104:"효과적인 팀",
  d105:"오늘 충전",
  d106:"오늘 데리러",
  d107:"개인의",
  d108:"팀",
  d109:"전원을 켠 후 캐스팅 조건이 충족되면 자동으로 캐스팅에 참여합니다.",
  d110:"충전 후 1~2분 이내 도착 예정",
  d111:"각 이체에 부과되는 {feerate}% 거래세",
  d112:"질문이 있으시면 질문 피드백을 남겨주세요!",
d113:"피드백 질문",
d114:"피드백 목록",
d115:"피드백 내용",
d116:"온라인 고객 서비스",
d117:"아직 답장 없음",
d118:"회신하다:",
d119:"1. 피드백",
d120:"2. 사진 추가(최대 3장)",
d121:"제출하다",
d122:"png 또는 jpg 형식의 이미지를 업로드하세요.",
d123:"업로드 중입니다. 잠시만 기다려 주세요...",
d124:"파일 크기는 10M를 초과할 수 없습니다.",
d125:"사용자",
d126:"CIRCLES 마이닝 풀",
d127:"총 발행량",
d128:"전류 출력",
d129:"발췌",
d130:"내 균형",
d131:"확인하다",
d132:"공유할 사람 수",
d133:"일하는 광부",
d134:"시간",
d135:"가리키다",
d136:"두번째",
d137:"채광",
d138:"마이닝 시작",
d139:"친구를 초대",
d140:"친구들이 채굴을 시작합니다",
d141:"공유 수익을 얻으십시오",
d142:"첫 번째 수준 공유",
d143:"주소",
d144:"상태",
d145:"더 이상은 없어",
d146:"보조 공유",
d147:"제네시스 마이닝",
d148:"입력하다",
d149:"CIRCLES는 암호화된 소셜 개인 정보 보호 공개 체인 구축에 전념하고 있으며 2025년 출시 예정입니다. 공개 체인 토큰의 총 수는 20억 개입니다. 각 사용자는 1T의 컴퓨팅 성능을 받을 수 있으며, 이는 총계가 될 때까지 점차 감소합니다. 80%는 무료로 채굴됩니다.",
d150:"총 자산 평가",
d151:"9·18 통과",
d152: "9·18 토큰은 WaterSwap이 협력한 최초의 역사 테마 커뮤니티 토큰입니다. 모두가 보유하고 결코 잊지 않을 것이라는 목표로 우리는 역사를 기억하고 희석을 방지합니다. 9·18 토큰부터 시작합니다! 이제 무료로 받으세요!",
d153:"채굴 규칙",
d154: "각 사용자는 1T의 컴퓨팅 파워를 얻을 수 있습니다. 각 컴퓨팅 파워 1T는 처음 24시간 동안 30코인을 생성합니다. 컴퓨팅 파워는 1천만 코인이 생성될 때마다 20%씩 감소합니다.",
d155: "직접 초대 시 50%, 간접 초대 시 25% 컴퓨팅 파워 보너스 인센티브!",
d156: "에어드롭 받기",
d157: "출금이 아직 열리지 않았습니다",
d158: "시장 리더를 결속하면 9.18 918 토큰 에어드랍을 받을 수 있습니다.",
d159: "공유에 참여하면 공유하고 에어드랍을 받는 사용자 10명당 최대 918까지 또 다른 에어드랍 보상을 받을 수 있습니다!",
d160:"나의 에어드롭",
d161:"포괄적 에어드롭",
d162:"나의 공유",
d163: "출금이 아직 열리지 않았습니다.",
d164:"현재 레벨",
d165:"수익 보기",
d166:"일반 사용자",
d167:"VIP{level} 회원",
d168:"업그레이드",
d169:"초대 코드 복사",
d170:"주소",
d171:"상태",
d172:"생태학",
d173:"카드 보유",
d174: "생태 건설에 참여하고 푸짐한 보상을 받으세요",
d175:"오늘 수집 가능",
d176:"C2C 거래 시장",
d177:"현재 레벨",
d178:"수신",
d179:"입력",
d180:"초대코드가 없습니다",
d181:"내 시장",
d182:"구매할 수 없습니다",
d183:"귀하의 리더는 회원이 아닙니다.",
d184: "체인의 균형이 부족함",
d185: "플랫폼 균형이 부족함",
d186: "서명 실패",
d187:"전송 실패",
d188:"갱신",
d189:"초대 코드",
d190:"결제해야 합니다",
d191:"지갑 잔액",
d192:"균형",
d193:"플랫폼 밸런스",
d194: "결제 진행 중...잠시 기다려주세요",
d195:"결제 성공",
d196:"결제 확인",
d197:"닫기",
d198:"결제 실패",
d199:"거래",
d200:"코인 획득",
d201:"에어드롭",
d202:"커뮤니티",
d203:"생태센터",
d204:'네트워크 선택',
d205:"조각",
d206:"받았다",
d207:"익숙하지 않은"
  };
  