import request from '../utils/request';
export default {
    //闪兑
    postexchangedo(data,address,language) {
        return request.request({
            url: '/exchange/do',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //闪兑条件
    getcondition(address,language) {
        return request.request({
            url: '/exchange/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
    //闪兑列表
    getexchangelist(data,address,language) {
        return request.request({
            url: '/exchange/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
          params:data
            
        })
    },
}