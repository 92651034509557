<template>
   
  <div class="xiangqing">
   <div class="recruit_title">
      <div style="transform: rotate(90deg);" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold;">{{ pagecont.title  }}</div>
      <div style="font-size: 12px; opacity: 0.6;"></div>
    </div>
  
   <div class="x_line1">
    <span>{{pagecont.author}}</span>
    <span>{{pagecont.created_time}}</span>
   </div>
   <div class="box">
    <div v-html="pagecont.content" style=""></div>
   
  

  

    

    
   </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { gongao } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
   topbutton
  },
  data() {
    return {
     pagecont:{}
    }
  },
 
  computed: {
   ...mapState(['account','lang']),
  },
  watch: {
   account () {
     this.init()
    },
    lang (){
     this.init()
    }
  },
  created() {
   if (this.$route.query.id) {
    
     this.init()
    }
  },
  methods: {
     init (){
      let data ={
         group:this.$route.query.id
      }
      gongao.getNoticeDetail(data).then(res =>{
         console.log(res);
         this.pagecont =res.data
      })
     },
     toprouter (){
        console.log(this.router);
        this.$router.go(-1);
     },
  },
}
</script>

<style lang="less" scoped>
.recruit_title{
  color: var( --text-color);
 padding: 0 20px;
 padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xiangqing{
   background: var(--bg-color);
    width: 100%;
    padding-top: 50px;
 .x_title{
    color: var( --text-color);
 font-size: 18px;
 font-weight: 500;
 font-family: "Poppins";
 width: 100%;
 text-align: center;

 }
 .x_line1{
    margin-left: 20px;
    span{
        margin-right: 20px;
        color: var( --text-color);
 font-size: 12px;
 font-weight: 700;
 font-family: "PingFang";
    }
 }
 .box{
   width: 100%;
    margin-top: 30px;
    padding: 33px 21px;
    box-sizing: border-box;
    width: 100%;
    min-height: 708px;
    border-radius: 30px 30px 0 0;
   background: var( --box-bg-color);
    color: var( --text-color);
 font-size: 14px;
 font-weight: 500;
 font-family: "PingFang";
 text-align: left;
 line-height: 21px;
 overflow: hidden;
 div{
   white-space: pre-wrap ;
   width: 100%;
  white-space: normal;
  word-break: break-word;

 }
 }
}
</style>