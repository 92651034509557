import request from '../utils/request';
export default {
    //公告列表
    getNoticeList(data,address,language) {
        return request.request({
            url: '/basic/notice/list',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
            
        })
    },

    //获取公告详情
    getNoticeDetail(data,address,language){
        return request.request({
            url: '/basic/notice/detail',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
    //反馈分类
    getbasiccategores(address,language){
        return request.request({
            url: '/basic/feedback/categories',
            method: "get",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
        })
    },
     //反馈列表
     getbasicfeedbacklist(data,address,language){
        return request.request({
            url: '/basic/feedback/list',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
      //反馈回复列表
      getbasicrelylist(data,address,language){
        return request.request({
            url: '/basic/feedback/reply/list',
            method: "get",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
      //反馈
      getfeebackdo(data,address,language){
        return request.request({
            url: '/basic/feedback/do',
            method: "post",
            headers:{
                Authorization : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
      //回复反馈
      getfeebackdoreply(data,address,language){
        return request.request({
            url: '/basic/feedback/do-reply',
            method: "post",
            headers:{
                Authorization : address,
                'Show-Language':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //上传图片
     postimgfile(data,address,language){
        return request.request({
            url: '/upload/image',
            method: "post",
            headers:{
                "Accept":"*/*",
                "Access-Control-Allow-Origin":"*",
                "Authorization" : address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
                "content-type": "multipart/form-data",
               
            },
            
           data:data,
         
        })
    }
}