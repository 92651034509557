<template>
  <div class="f_main">
    <div class="box">
     <keep-alive>
      <router-view  style="padding-bottom: 100px" />
     </keep-alive>
      
    </div>
    <div class="footer">
      <div class="f_li" v-for="(item, index) in footerlist" @click="bNav(item.router, item.i, index)" :key="index">
        <img :src="item.image" alt="" v-if="active != index" />
        <img :src="item.activeimage" alt="" v-else />
        <span v-if="active != index">{{ $t(item.name) }}</span>
        <span v-else style="color: var( --text-color)">{{ $t(item.name) }} </span>
      </div>
    </div>

    <!-- <div class="gonju" v-show="gonshow">
            <div class="g_li" v-for="item in gonlist" @click="gogonfun(item.router)">
                <span>{{  $t(item.name) }}</span>
            </div>



        </div>
        <div class="gonju dian" v-show="dianshow">
            <div class="g_li" v-for="item in dianlist" @click="gogonfun(item.router)">
                <span>{{  $t(item.name) }}</span>
            </div>



        </div> -->
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import { user } from '@/api/index';

export default {
  data() {
    return {
      active: 0,
      gonshow: false,
      dianshow: false,
      footerlist: [
        {
          image: require('@/assets/imgsnowball/shoyefalse.png'),
          activeimage: require('@/assets/imgsnowball/shoyetrue.png'),
          name: 'lang.h1',
          router: 'Index',
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/kuangchifalse.png'),
          activeimage: require('@/assets/imgsnowball/kuangchitrue.png'),
          name: 'lang.h2',
          router: 'earn',
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/jiaoyifalse.png'),
          activeimage: require('@/assets/imgsnowball/jiaoyitrue.png'),
          name: 'lang.h3',
          router: 'Swap',
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/fashetaifalse.png'),
          activeimage: require('@/assets/imgsnowball/fashetaitrue.png'),
          name: 'lang.h4',
          router: 'iso',
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/huobanfalse.png'),
          activeimage: require('@/assets/imgsnowball/huobantrue.png'),
          name: 'lang.h5',
          router: 'Invitation',
          i: '',
        },
      ],
      gonlist: [
        {
          name: 'lang.swap351',
          router: '',
        },
        {
          name: 'lang.swap352',
          router: '',
        },
        {
          name: 'C2C',
          router: '',
        },
        {
          name: 'lang.swap353',
          router: '',
        },
      ],
      dianlist: [
        {
          name: 'lang.swap349',
          router: 'Invitation',
        },
        {
          name: 'lang.swap350',
          router: '',
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.listRouteActive(to.name);
    },
    account() {
    
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.listRouteActive(this.$route.name);
  
  },
  mounted() {},
  methods: {
    
    gonshowfun() {
      this.gonshow = !this.gonshow;
    },
    listRouteActive(router) {
      console.log(router);
      for (var i in this.footerlist) {
        if (this.footerlist[i].router == router) {
          this.active = i;
          this.$forceUpdate();
        }
      }
    },
    bNav(router, i, index) {
      this.active = index;

      if (router != '') {
        this.$router.push({ name: router });
      } else {
      }
    },
    gogonfun(router) {
      this.gonshow = false;
      this.dianshow = false;
      this.$router.push({ name: router });
    },
  },
 
};
</script>
  
<style lang="less" scoped>
.box {
  
  // margin-bottom: 76px !important;
  // // margin-bottom: 76px;
  margin-top: 50px;
}
.f_main {
  width: 100%;
}

.footer {
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 76px;
  bottom: 0;
  left: 0;
  background: var( --box-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 15px;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.12);
  img {
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
  }

  div {
    display: flex;
    flex-direction: column;

    align-items: center;
    height: 100%;
    color: #6f7d96ff;
    font-size: 10px;
    font-weight: 500;
    font-family: 'PingFang';
  }
}

.gonju {
  width: 345px;
  height: 266px;
  border-radius: 30px 0 0 0;
  opacity: 1;
  background: var( --text-color);
  position: fixed;
  right: 0;
  bottom: 76px;
  padding: 25px 0px 0px 20px;
  z-index: 99;

  .g_li {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 22.4px;
    color: rgba(34, 34, 34, 1);
  }

  .zhezhao {
    width: 100%;
    height: 400px;
    background: #ddd;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }
}
.dian {
  height: 134px;
  bottom: 76px;
  .g_li {
    height: 50%;
  }
}
</style>