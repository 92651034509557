import request from '../utils/request';

export default {
    daouserinfo(address) {
        return request.request({
            url: '/dao/user/info',
            method: "get",
            headers: { 'Content-Type':'application/json',
                Authorization: address,
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
}