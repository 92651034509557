<template>
  <div class="chobi">
    <div class="chozhi">
      <div class="tonzhen" @click="tonzhenfunis">
        <span>{{ stokenname != ''? stokenname : $t('lang.d21')  }}</span>
        <img src="@/assets/imgsnowball/xiala.png" alt="" />
      </div>
      <van-popup v-model="tonzhenshow" round position="bottom" class="vantonzhenshow">
        <div class="t_li" v-for="(item, index) in tokens" @click="seclettokenfun(item)" :key="index">
          <img :src="`${imgurl}${item.logo}`" alt="" />
          <span style="color: var(--text-color)"> {{ item.token }}</span>
        </div>

        <div class="quxiao">
          <span @click="tonzhenfunis">{{ $t('lang.swap154') }}</span>
        </div>
      </van-popup>
      <p class="c_shul">{{ $t('lang.d33') }}</p>
      <van-field type="number"  v-model="toInput" input-align="left" :placeholder="`${$t('lang.d34')}${min}-${max}${stokenname}`" class="van-field" />
      <div class="line3">
        <p class="balance" v-show="stokenname != ''">{{ $t('lang.d30') }}{{ balance }}</p>
      </div>
      <div class="line3">
        <p class="balance" v-show="stokenname != ''">{{ $t('实际转出') }}：{{ ComTransferRate }}</p>
      </div>

      <p class="c_shul">{{ $t('lang.d35') }}</p>

      <van-field type="text" v-model="toaddress" input-align="left" :placeholder="`${$t('lang.d36')} `" class="van-field" />
      <div v-show="stokenname != ''" style="font-size: 14px; margin-top: 10px">
        <div>{{ $t('lang.d99') }}</div>
        <div>
          {{ $t('lang.d111', { feerate: feerate * 100 }) }}
        </div>
      </div>

      <div class="c_button" v-if="showbutton" @click="sendtranform">{{ $t('lang.d26') }}</div>
      <div class="c_button" v-else style="opacity: 0.6">{{ $t('lang.d26') }}</div>
    </div>
    <p class="c_jilu">{{ $t('lang.d37') }}</p>
    <div class="c_wlist" v-if="zhuanzhanglist.length == 0">
      <img src="@/assets/imgsnowball/wujilu.png" alt="" />
      <p>{{ $t('lang.d57') }}</p>
    </div>
    <div class="c_list" v-else>
      <van-list v-model="loading1" :finished="finished1" :finished-text="`${$t('lang.d145')}`" @load="onLoad1" class="list_box">
        <div class="li" v-for="(item, index) in zhuanzhanglist" :key="index">
        <div>
          <div class="c_head">
            <span v-if="item.behavior == 0"> {{ $t('lang.d84') }}</span>
            <span v-else-if="item.behavior == 1"> {{ $t('lang.d85') }}</span>
            {{ item.token_name }}
          </div>
          <div>{{ item.created_time }}</div>
        </div>
        <span v-if="item.type == '1'">-{{ item.amount }}</span>
        <span v-else>+{{ item.amount }}</span>
      </div>
      </van-list>
      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import { getErc20Contract } from '@/utils/contractHelp';
import { zhuanzhang, bizhong, homedata, moneymask } from '@/api/index';
export default {
  data() {
    return {
      tonzhenshow: false,
      toInput: '',
      toaddress: '',
      zhuanzhanglist: [],
      stokenname: '',
      tokens: [],
      balance: '',
      imgurl: '',
      sindex: '',
      tokenId: '',
      feerate: 0,
      loading1:false,
      finished1:false,
      page:1,
      max:0,
      min:0

    };
  },
  created() {
    this.init();
  },
  mounted() {
    console.log('edd', Boolean(this.zhuanzhanglist));
  },
  computed: {
    ...mapState(['account', 'lang']),
    showbutton() {
      return this.stokenname != '' && !!this.toInput && !!this.toaddress;
    },
    ComTransferRate(){
       if(this.toInput == ''){
         return 0
       }else{
        return Math.ceil(parseFloat(parseFloat(this.toInput) +  parseFloat(this.toInput*this.feerate)) * 100) / 100   
       }
      
    }
  
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  methods: {
    tonzhenfunis() {
      this.tonzhenshow = !this.tonzhenshow;
    },
    init() {
        this.page = 1
        this.zhuanzhanglist =[]
      this.getlist();
       this.gettransferlist()
      this.getimgurl();
    },
    onLoad1() {
        this.page++;
        this.gettransferlist()
    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },
    async geterc20banceof() {
      let data = {
        token_id: this.tokenId,
      };
      moneymask.getwalletgetinfo(data, this.account).then(res => {
        this.balance = res.data.usable;
      });
    },
    seclettokenfun(item) {
      this.tonzhenshow = false;
      this.stokenname = item.token;
      this.tokenId = item.token_id;
      this.feerate = item.fee_rate;
      this.max = item.max_amount;
      this.min = item.min_amount;
      this.geterc20banceof();
    },
    async sendtranform() {          
       if(this.ComTransferRate > this.balance) return  this.$toast(this.$t('lang.d80'));
    
        if (parseFloat(this.toInput)<= 0) return  this.$toast(this.$t('lang.d79'));
        
      this.sendf();
        
     
      
    },
    async sendf() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Transfer Out'), this.account);
      let data = {
        token_id: this.tokenId,
        amount: this.ComTransferRate,
        to_address: this.toaddress,
        signature: sign,
      };
       zhuanzhang.posttransferout(data, this.account).then(res => {
        if(res.code == 200){
          this.init()
        }

        this.$toast(res.msg);
      });
    },
    gettransferlist(){
        let data = {
        page: this.page,
        page_size: 10,
      };
      zhuanzhang.gettransferlist(data, this.account).then(res => {
      
        if (res.data.list.length != 0) {
          this.zhuanzhanglist = [...this.zhuanzhanglist, ...res.data.list];
        
        }

        if (res.data.list.length < 10) {
          this.finished1 = true;
        }
      
      });
    },
    getlist() {
      zhuanzhang.gettransfercondition(this.account).then(res => {
        console.log(res);
        this.tokens = res.data;
      });

     
    },
  },
};
</script>

<style lang="less" scoped>
.chobi {
  padding: 20px;
  width: 100%;
  background: var(--box-bg-color);
  border-radius: 20px;
}

.chozhi {
  width: 100%;
  padding: 15px 20px;

  border-radius: 20px;
  background: ;

  .tonzhen {
    width: 100%;
    height: 52px;
    border-radius: 87px;
    background: var(--line-bg-color);
    padding: 15px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 10px;
      height: 10px;
    }

    span {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }

  .vantonzhenshow {
    padding: 20px;
    background: var(--line-bg-color);
    .t_li {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #FF666666;

      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        border-radius: 50%;
      }
    }

    .quxiao {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'PingFang SC';
    }
  }

  .c_shul {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
    margin-top: 30px;
  }

  .van-field {
    width: 100%;
    border-radius: 87px;
    font-weight: bold;
    background: var(--line-bg-color) !important;
    padding: 16px 20px !important;

    /deep/ .van-field__control {
      font-size: 14px;
      color: var(--text-color);
    }
  }

  .balance {
    color: #9395a4ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .ruzhang {
    position: relative;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .r_line {
      height: 15px;
      width: 1px;
      background: #56a0b7;
      position: absolute;
      right: 60px;
      top: 20px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .c_button {
    margin-top: 30px;
    width: 100%;
    height: 55px;
    border-radius: 110px;
    background: rgba(102, 180, 174, 1);
    box-shadow: 0 20px 30px 0 #191c321a;
    text-align: center;
    line-height: 55px;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_jilu {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
  margin-top: 30px;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: var(--line-bg-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_list {
  width: 100%;
  background: var(--line-bg-color);
  padding: 15px 18px;
  border-radius: 20px;

  .li {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .c_head {
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
      }

      .c_time {
        color: var(--text-color);
        font-size: 12px;
        font-weight: 500;
        font-family: 'PingFang';
      }
    }

    span {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}

.zhihui {
  margin-top: 30px;
  width: 100%;
  height: 55px;
  border-radius: 65px;
  opacity: 1;
  box-shadow: 0 20px 30px 0 #191c321a;
  text-align: center;
  line-height: 55px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: #4583ff;
}

.op {
  opacity: 0.4;
}
</style>