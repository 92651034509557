<template>
    <div class="starbox">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px">  {{ $t('lang.星级指南') }}</div>
            <div style="width: 10%;"></div>
        </div>
        <div class="box">
            <div class="title"><van-icon name="star" color="rgba(73, 140, 147, 1)" />  {{ $t('lang.星级介绍') }}</div>
            <div class="text">
                 {{ $t('lang.根据团队当前持有有效的星石矿卡评定星级用户，分为5个等级') }}
            </div>
            <img src="@/assets/imgsnowball/star_charts.png" class="star_charts" alt="">
        </div>
        <div class="box">
            <div class="title"><van-icon name="star" color="rgba(73, 140, 147, 1)" />   {{ $t('lang.星级权益') }}</div>
            <div class="text">
                <div class="text_left">{{ $t('lang.R级') }}:</div>
                <div class="text_cont">{{ $t('lang.可同时持有19张矿卡,并获得小业绩区新增业绩1%的加速！') }}</div>
            </div>
            <div class="text">
                <div class="text_left"> {{ $t('lang.S级') }}:</div>
                <div class="text_cont">{{ $t('lang.可同时持有29张矿卡,并获得小业绩区新增业绩3%的加速！') }}</div>
            </div>
            <div class="text">
                <div class="text_left"> {{ $t('lang.SR级' ) }}:</div>
                <div class="text_cont"> {{ $t('lang.可同时持有39张矿卡,并获得小业绩区新增业绩5%的加速!在出现平级时可获得50%平级收入孵化津贴！') }}</div>
            </div>
            <div class="text">
                <div class="text_left"> {{ $t('lang.SSR级') }}:</div>
                <div class="text_cont">{{ $t('lang.可同时持有49张矿卡,并获得小业绩区新增业绩7%的加速!在出现平级时可获得40%平级收入孵化津贴！') }} </div>
            </div>
            <div class="text">
                <div class="text_left"> {{ $t('lang.UR级') }}:</div>
                <div class="text_cont">{{ $t('lang.可同时持有49张矿卡,并获得小业绩区新增业绩9%的加速!在出现平级时可获得30%平级收入孵化津贴！') }}</div>
            </div>
        </div>
        <div class="box">
            <div class="title"><van-icon name="star" color="rgba(73, 140, 147, 1)" />{{ $t('lang.其他说明') }} </div>
            <div class="text">
                {{ $t('lang.当团队持有矿卡到期后，有效张数失效，级别存在下降可能！') }}
                
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
        toprouter() {
            this.$router.go(-1);
        },
    },
}

</script>
<style scoped lang='less'>
.recruit_title {
    color: #000;
    padding: 20px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.starbox {
    padding-top: 52px;
    padding-bottom: 44px;
    background: rgba(255, 255, 255, 1);
}

.box {
    padding: 24px;
    border-bottom: 8px solid rgba(245, 245, 245, 1);

    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 8px;
    }

    .star_charts {
        margin-top: 10px;
        width: 100%;
    }

    .text {
        font-size: 13px;
        font-weight: 500;
        display: flex;
        margin-bottom: 10px;
        .text_left{
             width: 10%;
            color: rgba(0, 0, 0, 1);
            font-weight: 700;
        }
        .text_cont{
            width: calc(100% - 10px);
            letter-spacing: 0px;
line-height: 19.5px;
        }
    }
}
</style>