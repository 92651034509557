import request from '../utils/request';
export default {
    //购买
    nftholdbuy(data,address) {
        return request.request({
            url: '/nft/hold/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    //激活
    nftholdactivation(data,address) {
        return request.request({
            url: '/nft/hold/activation',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //领取
     nftholdclaim(data,address) {
        return request.request({
            url: '/nft/hold/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
      //持有列表
      nftholdlist(data,address) {
        return request.request({
            url: '/nft/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
      //持有列表
      nftholdlist(data,address) {
        return request.request({
            url: '/nft/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
      //账单列表
      nftwalletrecord(data,address) {
        return request.request({
            url: '/nft/wallet/record',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
     //资产
     nftwalletinfo(address) {
        return request.request({
            url: '/nft/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftlist(address) {
        return request.request({
            url: '/nft/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftrankinglist(address) {
        return request.request({
            url: '/nft/ranking/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftsummaryinfo(address) {
        return request.request({
            url: '/nft/hold/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftteaminfo(address) {
        return request.request({
            url: '/nft/team/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    walletinfobykey(data,address) {
        return request.request({
            url: '/wallet/info-by-key',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    nftchildrenlist(data,address) {
        return request.request({
            url: '/nft/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    nftwhitelistinfo(address) {
        return request.request({
            url: '/nft/whitelist/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftwhitelistbuy(data,address) {
        return request.request({
            url: '/nft/whitelist/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    nftreleaserecord(data,address) {
        return request.request({
            url: '/nft/release/record',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
    
    nftstaticrewardclaim(data,address) {
        return request.request({
            url: '/nft/sr/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    nftsendconfigs(address) {
        return request.request({
            url: '/nft/send/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftsendholdlist(data,address) {
        return request.request({
            url: '/nft/send/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
            params:data
        })
    },
    nftuserrecommender(address) {
        return request.request({
            url: '/nft/user/recommender',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            }, 
        })
    },
    nftuserbind(data,address) {
        return request.request({
            url: '/nft/user/bind',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    nftsrallclaim(data,address) {
        return request.request({
            url: '/nft/sr/all-claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    nftsrinfo(address) {
        return request.request({
            url: '/nft/sr/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
}