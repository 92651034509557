import request from '../utils/request';
export default {
    //币种行情
    gettokenquote(address,language) {
        return request.request({
            url: '/token/quote',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
    //提币配置
    gettokenwithdrawconfigs(address,language) {
        return request.request({
            url: '/withdraw/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
    //充币配置
    gettokenrechargeconfigs(address,language) {
        return request.request({
            url: '/recharge/configs',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
     //D6涨跌
     getdchart(data,address,language) {
        return request.request({
            url: '/token/d6-chart',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
            
        })
    },
     //D6信息
     gettokeninfo(address,language) {
        return request.request({
            url: '/token/d6-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           
            
        })
    },
    walletentrymark(data,address,language) {
        return request.request({
            url: '/wallet/entry-mark',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
}