import request from '../utils/request';
export default {
    //挂单
    c2cmarketpublish(data,address) {
        return request.request({
            url: '/c2c/market/publish',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //市场挂单列表
     c2cmarketlist(data,address) {
        return request.request({
            url: '/c2c/market/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
     //撤单
     c2cmarketcancel(data,address) {
        return request.request({
            url: '/c2c/market/cancel',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
    //购买
    c2cmarketbuy(data,address) {
        return request.request({
            url: '/c2c/market/buy',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //出售
     c2cmarketsell(data,address) {
        return request.request({
            url: '/c2c/market/sell',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
        })
    },
     //交易区
     c2czone(address) {
        return request.request({
            url: '/c2c/zone',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
      //最新成交价
      c2cmarketlatestprice(data,address) {
        return request.request({
            url: '/c2c/market/latest-price',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params:data
        })
    },
      //用户挂单列表
      c2ctradelist(data,address) {
        return request.request({
            url: '/c2c/trade/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           params:data
        })
    },
 //用户成交记录
 c2ctraderecords(data,address) {
    return request.request({
        url: '/c2c/trade/records',
        method: "get",
        headers:{
            Authorization : address,
            'Content-Type':'application/json',
            'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
        },
       params:data
    })
},
   //c2c资产
   c2cassets(address) {
    return request.request({
        url: '/c2c/assets',
        method: "get",
        headers:{
            Authorization : address,
            'Content-Type':'application/json',
            'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
        },
    })
},
}  