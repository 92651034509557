<template>
    <div class="gamemarket">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="joinTitle">
            <span>{{ $t('lang.欢迎进入') }}</span>
            <span>{{ $t('lang.49DAO娱乐链游生态平台') }}</span>
        </div>
        
        <div class="joinText">
            <span>{{ $t('lang.49链游是49DAO联合多个厂商') }}</span>
        </div>
        <div class="joinButton" @click="smoothTo">{{ $t('lang.参与游戏建设') }}</div>
     
        <div class="ComingSoonTitle" id="myElementId">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('lang.即将到来') }}</span>
        </div>
       
        <div class="game_box_video"  v-for="(item, index) in moreGameList" :key="index">
            <!-- <video src="@/assets/img/gameImage/启动引擎.mp4" controls="controls" class="game_video"></video> -->
             <img :src="item.image" alt="" class="game_video">
            <div class="game_box_title">{{ $t(item.title) }}</div>
            <div class="game_box_text">{{ $t(item.text) }}</div>
        </div>
        <div class="ComingSoonTitle">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('lang.更多游戏') }}</span>
        </div>

        <div class="joinText">
            <span>{{$t('lang.49DAO将推动多个游戏厂商进行联盟合作')}}</span>
        </div>
        
        <div class="gameList">
            <div class="gameList_item" v-for="(item, index) in gameList" :key="index">
                <img :src="item.image" alt="">
            </div>
        </div>
     
        <div class="ComingSoonTitle">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('lang.合作伙伴') }}</span>
        </div>
        <div class="partnerBox">
            <img src="@/assets/img/pathers_bg.png" alt="">
        </div>

        <div class="happydex_icon" style="display: flex;">
            <img src="@/assets/img/oneswap_icon.png" alt="">
            <span>ONE SWAP</span>
        </div>
        <div class="GoBuyBox">
            <div class="GoBuyBox_left">
                <div class="GoBuyBox_left_item" style="margin-bottom: 25px;">
                    <span>{{banlace.ONE.token}} : ${{banlace.ONE.usable}}</span>
                </div>
                <div class="GoBuyBox_left_item">
                    <span>RAC : $--</span>
                </div>
            </div>
            <div class="GoBuyBox_right" @click="goTo('Swap')">
                <span>{{ $t('lang.去购买') }}</span>
                <div style="margin-left: 11px; transform: rotate(-90deg);">
                    <van-icon name="down" />
                </div>

            </div>
        </div>
        <div class="ecologyList">
            <div style="font-size: 20px; font-weight: bold; color: rgba(0, 0, 0, 1);">{{ $t('lang.生态系统') }}</div>
            <div v-for="(item, index) in ecologyList" :key="index" @click="goTo(item.router)">
                {{ $t(item.name) }}
            </div>
        </div>
    </div>
</template>

<script>
import { happydex, nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    name: '',
    components: {

    },
    data() {
        return {
            gameList: [
                {
                    image: require('@/assets/img/game1.png')
                },
                {
                    image: require('@/assets/img/game2.png')
                },
                {
                    image: require('@/assets/img/game3.png')
                },
                {
                    image: require('@/assets/img/game4.png')
                },
                {
                    image: require('@/assets/img/game5.png')
                },
                {
                    image: require('@/assets/img/game6.png')
                },
                {
                    image: require('@/assets/img/game7.png')
                },
                {
                    image: require('@/assets/img/game8.png')
                }
            ],
            moreGameList:[
                {
                    image: require('@/assets/img/video_game1.png'),
                    title:'lang.火柴人战争遗产',
                    text:'lang.火柴人战争遗产简介',
                },
                {
                    image: require('@/assets/img/video_game2.png'),
                    title:'lang.平凡大冒险',
                    text:'lang.平凡大冒险简介',
                },
                {
                    image: require('@/assets/img/video_game3.png'),
                    title:'lang.刺客猎人',
                    text:'lang.刺客猎人简介',
                },
                {
                    image: require('@/assets/img/video_game4.png'),
                    title:'lang.地下城割草',
                    text:'lang.地下城割草简介',
                },
            ],
            ecologyList: [
                {
                    name: 'lang.swap88',
                    router: 'Swap',
                },
                {
                    name: 'lang.h2',
                    router: 'earn',
                },
                {
                    name: 'lang.NFT中心',
                    router: 'nftCard',
                },
                {
                    name: 'Real A',
                    router: 'NFTCenter',
                },
                {
                    name: 'SuperOne',
                    router: 'SuperOne',
                }
            ],
            banlace: {
                ONE: {},
                RAC: {}
            }
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
         this.init()
    },
    methods: {
        init() {
            this.getbanlace()
        },

        async getSign(){
            let web3 = new Web3(window.ethereum);
           const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Game Encrypt Address'), this.account);
          happydex.gameencryptaddr({signature:sign},this.account).then(res=>{
                        
                       if(res.code == 200){
                          window.location.href ='https://game.happydex.cc?token_value='+res.data  
                       }else{
                        this.$toast(res.msg)
                       }
          })
        },
        getbanlace() {
            nft.walletinfobykey({ token_key: 'ONE' }, this.account).then(res => {
                if (res.code == 200) {
                    this.banlace.ONE = res.data
                }

            })
            // nft.walletinfobykey({ token_key: 'GOC' }, this.account).then(res => {
            //     if (res.code == 200) {
            //         this.banlace.GOC = res.data
            //     }
            // })
        },
        goTo(router) {
            if (router != '') {
                 if(router == 'SuperOne'){
                    window.open('https://supper.onedex.club/pages/login/index')
                    return;
                 }
                this.$router.push({ name: router });
            } else {
                this.$toast(this.$t('lang.h160'));
            }
        },
        smoothTo() {
            var element = document.getElementById('myElementId');
            element.scrollIntoView({ behavior: "smooth" });
        },
        toprouter() {
            this.$router.go(-1);
        },
    },
}
</script>

<style lang="less" scoped>
.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 0px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.game_image{
    width: 100%;
height: 160px;
img{
    width: 100%;
    height: 100%;
}
}

.gamemarket {
    background: rgba(13, 8, 26, 1);
    padding: 20px;
    padding-top: 64px;
    padding-bottom: 147px;
    background: url('~@/assets/img/game_bg.png') no-repeat;
    background-size: contain;
}
.game_box_video{
    margin: 37px 0 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px;
    padding-bottom: 47px;
    border-radius: 30px;
background: rgba(255, 255, 255, 1);
box-shadow: 0px 2px 16px  rgba(68, 68, 68, 0.06);
    .game_video{
        border-radius: 30px;
        width: 100%;
        height: 171px;
        margin-bottom: 30px;
    }
    .game_box_title{
        font-size: 20px;
font-weight: bold;
letter-spacing: 2px;
line-height: 21.2px;
color: rgba(34, 34, 34, 1);
margin-bottom: 20px;
    }
    .game_box_text{
        font-size: 13px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18.2px;
color: rgba(102, 102, 102, 1);
vertical-align: top;
    }
}

.ecologyList {
    margin-top: 26px;

    &>div {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 21px;
        color: rgba(102, 102, 102, 1);
        margin-bottom: 20px;
    }
}

.GoBuyBox {
    border-bottom: 4px solid rgba(240, 245, 243, 1);
    border-top: 4px solid rgba(240, 245, 243, 1);
    width: 100%;
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .GoBuyBox_right {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 27px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 36px;
        opacity: 1;
        border-radius: 110px;
        background: rgba(102, 180, 174, 1);
    }

    .GoBuyBox_left {
        .GoBuyBox_left_item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 18.75px;
            color: rgba(34, 34, 34, 1);

            img {
                margin-right: 10px;
                width: 22px;
                height: 25px;
            }
        }
    }
}

.happydex_icon {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
    img {
        width: 39px;
        height: 40px;
        margin-right: 9px;
    }
}

.partnerBox {
    padding: 12px 16px;
    margin-top: 20px;
    margin-bottom: 42px;
    width: 100%;
    height: 238px;
    opacity: 1;
    border-radius: 20px;

    img {
        width: 100%;
        height: 100%;
    }
}

.gameList {
    margin-top: 39px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .gameList_item {
        margin-bottom: 22px;
        width: 148.01px;
        height: 143.34px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.ComingSoonTitle {
    position: relative;
    width: 100%;
    text-align: center;

    .ComingSoonTitle_icon {
        width: 36px;
        height: 17px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 30px;
  color: rgba(34, 34, 34, 1);
}

.joinButton {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 345px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 27px;
    color: #fff;
    width: 218px;
    height: 46px;
    opacity: 1;
    border-radius: 110px;
    background: rgba(102, 180, 174, 1);
}

.joinText {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 19.6px;
    color: rgba(0, 0, 0, 1);
    text-align: justify;
    vertical-align: top;
}

.joinTitle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 30px;
  color: rgba(34, 34, 34, 1);

    .joinTitle_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 296.54px;
        height: 34.85px;
    }
}
</style>