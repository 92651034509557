// import addresses from '@/config/constants/contracts.js';
//原onedex底池信息
// jackRouter: '0xA3eCBD7E1d95eB6662a64DFb96560AC909f58Bf4',
// jackFactory: '0xF31480d5edf8f49D6525223516EE05cCeB71B4A7',
// weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
const addresses = {
  jackRouter: '0xA46FDcA0Ce0173BD80656ccfD6d1cE9d84f0157D',
  jackFactory: '0x5CD2a6e2531b14FFDc005C642D2ACc2159131B27',
  weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
};

export const getAddress = address => {
  return address;
};

export const getJackFactoryAddress = () => {
  return getAddress(addresses.jackFactory);
};

export const getJackRouterAddress = () => {
  return getAddress(addresses.jackRouter);
};

export const getWethAddress = () => {
  return getAddress(addresses.weth);
};
