<template>
    <div class="snowballmain">
        <Header1 v-if="BlueListincludes"></Header1>
   <Header2  v-else></Header2>
        <div class="box">
             
            <router-view />
        </div>
    </div>
</template>
<script>

import Header2 from '@/components/Header2.vue';
import Header1 from '@/components/Header.vue';
const BlueList = ['ZhuangZhan3','TiBi2','ChoBi1','shandui','shequ','fankui','fankuiadd','fanxiangqin','zhangdan','gongao','xiangqing']
export default {
    name: "Home",
    data() {
        return {
      
        };
    },
    computed:{
        BlueListincludes(){
            console.log('this.$route.name',this.$route.name);
            return BlueList.includes(this.$route.name)
        }
    },
    mounted() {
      console.log(this.$route.name);
    },
    methods: {
      
    },
    components: { Header2,Header1 }
};
</script>
  
<style lang="less" scoped>
.snowballmain {
    width: 100%;
    background: #fff;
    // padding: 20px;
  height: 100%;
   margin-top: 50px;
}



</style>
  