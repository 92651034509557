<template>
  <div id="app">
   
    <router-view class="router" />
   
    <!-- <BottomNavigation></BottomNavigation> -->

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { simpleRpcProvider } from '@/utils/provider';


// import BottomNavigation from '@/components/BottomNavigation.vue';
export default {
  name: 'App',
  components: {
    
   
    // BottomNavigation
    
   
},
  beforeCreate() {
    // setTimeout(() => {
    //   this.$store.dispatch('setWebProvider');
    // }, 1000);
    // this.$store.dispatch('setWebProvider');
  },
  created () {
    
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'transactions', 'block', 'expert']),
  },
  async created() {
    this.$store.dispatch('setWallet')
    this.$store.dispatch('setWebProvider');
    setTimeout(() => {
      this.$store.dispatch('setWebProvider');
      
    }, 1000);
    this.$store.commit('SETACCOUNTS', '');
    // sessionStorage.getItem('account') ? sessionStorage.getItem('account') : 'en-US'
    // sessionStorage.setItem('account', item.lang);
    // commit('SETACCOUNTS',  sessionStorage.getItem('account') ? sessionStorage.getItem('account') : 'en-US');
    const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
    this.$store.commit('SETBLOCK', blockNumber);
    this.timer = setInterval(async () => {
      const blockNumber = await simpleRpcProvider.eth.getBlockNumber();
      this.$store.commit('SETBLOCK', blockNumber);
    }, 6000);
  },
  watch: {
    block() {
      this.handleUpdateTransactions();
    },
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
    this.$store.commit('SETACCOUNTS', '');
  },

  methods: {
    async handleUpdateTransactions() {
      if (!this.account) {
        return;
      }
      const allTransactions = Object.values(this.transactions);
      const pendTransactions = allTransactions.filter(item => {
        return !item.receipt && item.from.toLowerCase() == this.account.toLowerCase();
      });
      pendTransactions.forEach((item, index) => {
        simpleRpcProvider.eth.getTransactionReceipt(item.hash).then(receipt => {
          if (receipt) {
            this.$store.commit('SETTRANSACTIONSRECEIPT', {
              hash: item.hash,
              receipt,
            });
            if (document.documentElement.clientWidth < 400) {
              // if (receipt.state) {
              this.$message({ type: 'success', title: item.title, message: item.message, hash: receipt.hash });
              // } else {
              //   this.$message({ type: 'error', title: item.title, message: item.message, hash: receipt.hash });
              // }
            } else {
              // if (receipt.state) {
              this.$message({
                position: 'top-right',
                type: 'success',
                title: item.title,
                message: item.message,
                hash: receipt.hash,
              });
              // } else {
              //   this.$message({
              //     position: 'top-right',
              //     type: 'error',
              //     title: item.title,
              //     message: item.message,
              //     hash: receipt.hash,
              //   });
              // }
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="less" >
#app {
  font-family: Roboto, Segoe UI, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  // background: #0D0C0F;
  width: 100%;
  height: 100%;
//  background: #0A162D;
  image-rendering: -webkit-optimize-contrast;
  // .router {
  //   height: calc(100% -50px);
   
  // }
}
/deep/.van-toast{
  top: 40% !important;
}
</style>
