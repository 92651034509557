<template>
  <div>
    <div class="title" style="padding-top: 20px;">
        <topbutton router="fankui" style="position: absolute; left: 20px; top: 20px;"></topbutton>
        <span>{{$t('lang.d115')}}</span>
    </div>
    <div class="addfan ">
   
    <div class="main">
      <p>{{$t('lang.d119')}}</p>
      <van-field
  v-model="message"
    heigth="500"
  autosize
 
  type="textarea"
  maxlength="800"
  
  placeholder="请填写内容（800字以内）"
  show-word-limit
/>
<p>{{$t('lang.d120')}}</p>
<van-uploader 
            v-model="infoData" 
            multiple 
            :max-count="3" 
            :max-size="10 * 1000 * 1024" 
            @oversize="onOversize"
            :before-read="beforeRead"
            :before-delete="beforeDelete"
            :after-read="afterRead">
            <img
                class="add"
                src="@/assets/imgsnowball/zhanwei.png"
                alt=""/>
        </van-uploader>
<div class="tijiao" @click="tijiao" v-if="showbutton">{{$t('lang.d121')}}</div>
<div class="tijiaox" v-else>{{$t('lang.d121')}}</div>

    </div>
   
  </div>
  </div>
  
</template>

<script>
import topbutton from '@/components/topbutton.vue';
import { ImagePreview } from 'vant';
import { mapState } from 'vuex';
import { basic } from '@/api/index'
import axios from 'axios'
import { Toast, Uploader } from 'vant'
import Web3 from 'web3';
export default {
  name: '',
  components: {
    topbutton
  },
  computed: {
    ...mapState(['account','lang']),
    showbutton() {
            return !!this.message && this.fileList.length>0;
        },
  },
  watch: {
    account () {
      //获取账号
      if(!!this.account){
        this.init();
      }
      
    },
  lang (){
    
    this.init();
   
  },
},
  data() {
        return {
          message:"",
            fileList:[],  // 上传的图片列表
            infoData:[],  // 页面显示的数据
        }
    },
    
    methods:{
        // 判断文件大小
        onOversize(file) {
            Toast(this.$t('lang.d124'));
        },
        // 判断文件类型
        beforeRead(file) {
            if (file.type != 'image/png' 
                && file.type != 'image/jpg' 
                && file.type != 'image/jpeg') {
                Toast(this.$toast('lang.d122'));
                return false;
            }
            return true;
        },
        // 上传图片
      async  afterRead(result){
          
            var formData =require('form-data')
            var data = new formData()
            data.append('type','1')
            data.append('file',result.file)
          
         
            const loading = Toast.loading({
                forbidClick: true,
                text:this.$toast('lang.d123'),
            })
             
            basic.postimgfile(data,this.account).then((res) => {
                if (res.code == 200) {
                    // 请求成功后返回图片链接
                    this.fileList.push(res.data.save_path);
                    console.log(this.fileList);
                } else {
                    this.$toast(res.msg);
                }
            }).finally(()=>{
                loading.close()
            })

  },
        // 删除
        beforeDelete(result,detail){
            // console.log('beforeDelete',detail.index);
            this.fileList.splice(detail.index, 1);
            this.infoData.splice(detail.index, 1);
        },
       async tijiao (){
          let web3 = new Web3(window.ethereum);
            const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Feedback Post'), this.account)
            console.log("sign",sign);
          let data ={
            // cate_id:1,
            content :this.message,
            img_arr:this.fileList,
            signature:sign
          }
           basic.getfeebackdo(data,this.account).then(res =>{
            console.log(res);
            if(res.code ==200){
              this.$toast(res.msg)
              this.$router.push('fankui')
            }else{
              this.$toast(res.msg)
            }
           })
        }
    },


}
</script>

<style lang="less" scoped>
  .title{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
   
    
    
   
    span{
        color: var( --text-color);
 font-size: 18px;
 font-weight: 700;
 font-family: "PingFang";
    }
}
.addfan{
  width: 100%;
  
  border-radius: 20px 20px 0 0;
}
.main{
 
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  
   p{
    color: #000;
 font-size: 18px;
 font-weight: 700;
 font-family: "PingFang";
   }
}
/deep/.van-cell{
  background: #F7F7F7 !important;
  width: 100%;
  min-height: 224px;
  padding: 15px !important;
  border-radius: 10px;
}
/deep/.van-field__control{
  color: #000;
}
/deep/.van-field__word-limit{
  // position: absolute;
  // right: 0;
  // bottom: 0;
  margin-top: 135px;
}
.add{
  width: 61px;
  height: 61px;
}
/deep/.van-image__img{
  width: 61px;
  height: 61px;
}
/deep/.van-uploader__preview-image{
  width: 61px;
  height: 61px;
}
.tijiao{
  height: 55px;
border-radius: 10px;
opacity: 1;
background: rgba(102, 180, 174, 1);
box-shadow: 0 20px 30px 0 #191c321a;
color: #fff;
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
 text-align: center;
 line-height: 55px;
 margin-top: 70px;
}
.tijiaox{
  height: 55px;
border-radius: 16px;

background: rgba(102, 180, 174, 1);
color: #fff;
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
 text-align: center;
 line-height: 55px;
 margin-top: 70px;
}
</style>