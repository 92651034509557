import request from '../utils/request';
export default {
    //注册用户
    postregister(data,address,language) {
        return request.request({
            url: '/register',
            method: "post",
            headers:{
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
    //绑定推荐人
    postucbind(data,address,language) {
        return request.request({
            url: '/uc/bind-recommender',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },

    //编辑信息
    postucedit(data,address,language) {
        return request.request({
            url: '/uc/edit',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
           data:data
            
        })
    },
     //我的推荐人
     ucrecommender(address,language) {
        return request.request({
            url: '/uc/recommender',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         
            
        })
    },
     getucinviteinfo(address,language) {
        return request.request({
            url: '/uc/invite/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         
            
        })
    },
     //我的直推列表
     getucchildrenlist(data,address,language) {
        return request.request({
            url: '/member/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         params:data
            
        })
    },
     //个人信息
     getucinfo(address,language) {
        return request.request({
            url: '/uc/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        
            
        })
    },
      //water信息
      gettokenwaterinfo(data,address,language) {
        return request.request({
            url: '/token/water-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         params:data
            
        })
    },
     //water图表数据
     gettokenwaterchart( data,address,language) {
        return request.request({
            url: '/token/water-chart',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
         params:data
            
        })
    },
}