<template>
	<div class="interestsCenter">
		
		<div class="title">
			ONE DEX {{ $t('lang.提案公投') }}
		</div>
		<div class="text">
			{{ $t('lang.发起治理提案，并获得不少于10位董事支持后') }}，<br /> {{ $t('lang.则将进行下一步公投') }}。
		</div>
		<div class="goVote">
			{{ $t('lang.提交提案') }}
		</div>
		<div class="checkbox">
			 <div class="checkbox_list">
			 	 <div  :class="checkActive== 0? 'checkbox_list_li active':'checkbox_list_li'" @click="changeActive(0)">
			 	 	{{ $t('lang.进行中') }}
			 	 </div>
				<div  :class="checkActive== 1? 'checkbox_list_li active':'checkbox_list_li'" @click="changeActive(1)">
					{{ $t('lang.即将开始') }}
				</div>
				<div  :class="checkActive== 2? 'checkbox_list_li active':'checkbox_list_li'" @click="changeActive(2)">
					{{ $t('lang.已结束') }}
				</div>
			 </div>
			 <div class="search_box">
				 <input type="text" placeholder="搜索" />
			 	 <img src="@/assets/img/search_icon.png" mode="" />
			 </div>
		</div>
		<div class="interestsCenter_box">
			  <div class="nolist">
			  	    <img src="@/assets/img/no_list.png" mode="" />
					<div class="">
						{{ $t('lang.暂无数据') }}！
					</div>
			  </div>
		</div>
	</div>
</template>

<script>
	
	export default {
		data() {
			return {
				showExitDialog: false,
				indicatorDots: false,
				autoplay: false,
				interval: 3000,
				duration: 500,
				checkActive:0
			};
		},
		onLoad() {

		},
		components: {
		
		},
		computed: {

		},
		methods: {
			changeActive(index) {
               this.checkActive = index
			}
		},
	};
</script>

<style lang="less" scoped>
	.interestsCenter {
		width: 100%;
		min-height: 100vh;
		padding-bottom: 76px;
		background: url('~@/assets/img/recruitment_bg.png') no-repeat;
		background-size: contain;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			text-align: center;
			margin: 54px 0 10px 0;
			font-size: 28px;
			font-weight: 700;

			color: rgba(34, 34, 34, 1);
		}
		.text{
			font-size: 12px;
			font-weight: 500;
			color: rgba(102, 102, 102, 1);
			text-align: center;
			margin-bottom: 17px;
		}

		.goVote {
			width: 191px;
			height: 48px;
			border-radius: 72px;
			background: rgba(102, 180, 174, 1);
			box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 700;
			color: rgba(255, 255, 255, 1);
		}
	}

	

	.interestsCenter_box {
		min-height: 50vh;
		border-radius: 30px 30px 0px 0px;
		background: rgba(255, 255, 255, 1);
		width: 100%;
		padding: 20px 18px;
        padding-bottom: 40px;
		.nolist{
			width: 100%;
			height: 300px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
				font-size: 14px;
				font-weight: 400;
				color: rgba(153, 153, 153, 1);
			img{
				width: 171.07px;
				height: 121.69px;
				margin-bottom: 19px;
			}
		}
	}
.checkbox{
	width: 100%;
	padding: 0 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;
	margin-bottom: 18px;
	.checkbox_list{
		display: flex;
			width: 60%;
			align-items: center;
			justify-content: space-between;
		.checkbox_list_li{
			font-size: 16px;
			font-weight: 500;
			color: rgba(102, 102, 102, 1);
		}
		.active{
			font-size: 16px;
			font-weight: 700;
			color: rgba(69, 135, 143, 1);
		}
	}
	.search_box{
		padding: 10px;
		width: 78px;
		height: 30px;
		opacity: 1;
		border-radius: 42px;
		background: rgba(255, 255, 255, 1);
		display: flex;
		align-items: center;
		img{
			flex-shrink: 0;
			width: 10px;
			height: 10px;
		}
		input{
			font-size: 12px;
            border: none;
            width: 100%;
		}
	}
}
	
</style>