<template>
  <div>
    <router-view style="padding-bottom: 100px" />
    <NavBar></NavBar>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
export default {
  data () {
    return {

    }
  },
  components: {
    NavBar
  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
</style>