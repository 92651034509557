<template>
  <div style="background:var(--bg-color); padding-top: 52px;">
    <div class="recruit_title">
      <div style="transform: rotate(90deg);" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold;margin-left: 20px;">{{ $t('lang.d75') }}</div>
      <div style="font-size: 12px; opacity: 0;">XXX</div>
    </div>
  

      <van-list v-if="billList.list.length > 0" class="gongao" v-model:loading="billList.loading"
        :finished="billList.finished" finished-text="没有更多了" @load="onLoad">
        <div class="g_li" v-for="(item, index) in billList.list" @click="goxiangqing(item.group)" :key="index">
        <van-icon name="arrow" class="arrow" />
        <span class="span1">{{ item.title }}</span>
        <span class="span2">{{ item.created_time }}</span>
        <div class="line"></div>
        <span class="span3">{{ item.author }}</span>
      </div>
      </van-list>
     
    
  </div>

</template>

<script>
import { gongao } from '@/api/index';
import { mapState } from 'vuex';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {

  },
  components: { topbutton },
  data() {
    return {
      billList: {
        list: [],
        loading: false,
        finished: false,
        params: {
          page: 1,
          page_size: 10,
          type: 0
        }
      }
    }
  },

  created() {
    this.init();

  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  methods: {
    init() {
      this.billList = {
        list: [],
        loading: false,
        finished: false,
        params: {
          page: 1,
          page_size: 10,
          type: 0
        }
      }
     this.billList.params.type =  this.$route.query.type
      this.getNoticeList()
    },
    onLoad(){
      this.getNoticeList()
    },
    getNoticeList() {
    this.billList.loading = true
    gongao.getNoticeList(this.billList.params).then(res => {
        if (res.data.list.length > 0) {
            if (this.billList.params.page == 1) {
                this.billList.list = res.data.list
            } else {
                this.billList.list = this.billList.list.concat(res.data.list)
            }
            if (res.data.list.length < this.billList.params.page_size) {
                this.billList.finished = true
            } else {
                this.billList.params.page += 1
            }
            this.billList.loading = false
        } else {
            this.billList.finished = true
        }

    })
},
   
    goxiangqing(index) {
      this.$router.push({ name: 'xiangqing', query: { id: index } });
    },
    toprouter() {
      console.log(this.router);
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="less" scoped>
.recruit_title {
  color: var(--text-color);
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.g_title {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
}

.gongao {

  width: 100%;
  min-height: 100vh;
  padding: 20px;

  .g_li {
    margin-bottom: 14px;
    width: 100%;
    height: 119px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 15px 16px;
    background: var(--box-bg-color);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .arrow {
      position: absolute;
      color: var(--text-color);
      right: 20px;
      top: 20px;
    }

    .line {
      width: 100%;
      height: 0;
      opacity: 1;
      border: 1px solid rgba(83, 87, 112, 1);
    }

    .span1 {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
      font-family: "PingFang";
    }

    .span2 {
      color: var(--text-color);
      opacity: 0.6;
      font-size: 12px;

      font-family: "PingFang";
    }

    .span3 {
      color: var(--text-color);
      font-size: 12px;
      font-weight: 500;
      font-family: "Poppins";
    }
  }
}
</style>