import { render, staticRenderFns } from "./referendum.vue?vue&type=template&id=5a29ea7a&scoped=true&"
import script from "./referendum.vue?vue&type=script&lang=js&"
export * from "./referendum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a29ea7a",
  null
  
)

export default component.exports